import React, { Component } from 'react';
import './NotFound.css';


class NotFound extends Component {


    render() {

        return (
            <section className="page_404">
                <div className="404container">
                    <div className="page_bg">
                        <h1>404</h1>
                    </div>
                    <div className="box_404">
                        <h3 className="h2">
                            Ooops, look like you're lost
                        </h3>
                        <p>the page you are looking for does not exist!</p>
                        <a href="/" className="link_404">Go to Home</a>
                    </div>
                </div>
            </section>
        )
    }

}

export default NotFound;
