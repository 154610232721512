import React, { Component, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  //  Circle, 
  useMap,
  //  latLngBound 
} from 'react-leaflet';
import { connect } from "react-redux";
import L from "leaflet";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import icon from "./LeafletIcon";
import services from "../services/services.json";
import WHOLE from "../redux/statecore";
import DialogReportDetail from "./Dialog/DialogReportDetail";
import "./Leaflet.css";

// import IconButton from '@mui/material/IconButton';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import CloseIcon from '@mui/icons-material/Close';


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  WHOLE: (payload) => dispatch(WHOLE(payload)),
});

// var arrCoordinates = [
//   [40.911811463287414, 14.426960360311824],
//   [40.9400432764309, 14.40119100956776],
//   [51.705, -0.09],
//   [41.13254689847421, 14.39772493554454],
//   [40.887665099797104, 14.366836456319891],
//   [40.922798180677916, 14.405375316112861],
//   [40.940207659660885, 14.388688387130337],
// ];

// function MultipleMarkers() {
//   return arrCoordinates.map((coordinata, index) => {
//     return <Marker key={index} position={coordinata} icon={icon}></Marker>;
//   });
// }


const LocationMarker = ({ alertMarker, setCount, loadMarkers }) => {
  const [position, setPosition] = useState(null);
  // const [bbox, setBbox] = useState([]);

  const map = useMap();


  let green = new L.Icon({
    iconUrl: "/images/marker-icon-green.png",
    iconRetinaUrl: "/images/marker-icon-green.png",
    shadowUrl: "/images/marker-icon-green.png",
    iconSize: [38, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    // shadowSize: [41, 41],
    shadowAnchor: null,
    className: 'marker-svg'
  });


  // let greenIcon = new L.Icon({
  //   iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
  //   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  //   iconSize: [25, 41],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   // shadowSize: [41, 41],
  //   shadowAnchor: null,
  // });

  // let blueIcon = new L.Icon({
  //   iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
  //   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  //   iconSize: [25, 41],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   // shadowSize: [41, 41],
  //   shadowAnchor: null,
  // });


  useEffect(() => {
    //   map.on('zoomend', function(e) {
    //     myZoom.end = map.getZoom();
    //     var diff = myZoom.start - myZoom.end;
    //     if (diff > 0) {
    //         circle.setRadius(circle.getRadius() * 2);
    //     } else if (diff < 0) {
    //         circle.setRadius(circle.getRadius() / 2);
    //     }
    // });


    map.locate().on("locationfound", function (e) {
      const pos = [e.latlng.lat, e.latlng.lng];
      // console.log(map.getBounds());
      setPosition(pos);
      // console.log("MIA POSIZIONE - AREA ROSSA :" + pos);
      map.flyTo(pos, map.getZoom());
      const radius = e.accuracy;
      const circle = L.circle(e.latlng, radius, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.1
      });
      circle.addTo(map);
      // setBbox(e.bounds.toBBoxString().split(","));
    });
  }, [map, alertMarker]);



  map.on('moveend', function (event) {

    var bounds = map.getBounds();
    var count = 0;
    let arrayView = [];
    // console.log("PERIMETRO AREA SU DISPLAY : " + (JSON.stringify(bounds)));

    map.eachLayer(function (layer) {
      //console.log(layer._latlng);
      //console.log("LISTA leaflet_id " + layer._leaflet_id + " su mappa");
      // console.log("LAYER" + layer.options.item_id)
      // console.log("LAYER OPTIONS " + JSON.stringify(layer))




      if (layer instanceof L.Marker) {
        if (bounds.contains(layer.getLatLng())) {
          count++;
          arrayView.push(layer.options.item_id);
        }
      }
    });
    if (count < 1) {
      alertMarker();
      loadMarkers(arrayView);
      // alert("niente marker in quest'area");
      // setCount(count);   
    } else {
      setCount(count);
      loadMarkers(arrayView);
    };
    // console.log("MARKER VISIBILI : " + count);
    // console.log("ARRAYVIEW: " + arrayView);
    return count;
  }


  );


  return position === null ? null : (
    <Marker
      position={position}
      icon={green}
    >
      <Popup>
        You are here
        {/* {t('main.lbl_youarehere')} */}

      </Popup>
    </Marker>
  );
}



class Leaflet extends Component {


  constructor(props) {
    super(props);
    this.state = {
      clicks: [],
      items: this.props.items,
      // zoom:this.getDevice()==="Desktop" ? 9 : 5,
      zoom: 10,
      center: [50.83885, 4.37513], // centro temporaneo openAlertfino all'effetto volo su posizione corrente
      count: null,
      openDialogDetail: false,
      itemDetail: [],
      positionenabled: false,
      kilometres: 0,
      cover: "",
      avatar: "",
      nickname: "",
      showAlert: false,
    }
  }


  componentDidMount() {

    // console.log(this.props.items)
  }

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };


  open = () => {
    this.setState({ openDialogDetail: true });
  }

  openDialogDetail = (item) => {

    this.getkilometresItemDetail(item);
    this.getCoverItemDetail(item);
    this.getAvatarItemDetail(item);
    this.getNicknameItemDetail(item);
    this.getResourcesNumber(item);

    this.setState({ itemDetail: item }, () => this.open());

  }

  closeDialogDetail = () => {
    this.setState({ openDialogDetail: false });
  };

  getCoordinates = (item) => {
    return {
      lat: item.location.coordinates[1],
      lng: item.location.coordinates[0],
    }
  }

  getResourcesNumber = (item) => {
    let badge = item.resources.length
    // console.log(badge)
    this.setState({ badge: badge })
  }

  getkilometresItemDetail = (item) => {
    let kilometres = this.getDistance(item).toFixed(2);
    this.setState({ kilometres: kilometres })
  }

  getCoverItemDetail = (item) => {
    let cover = this.getUrl(item);
    this.setState({ cover: cover })
  }
  getAvatarItemDetail = (item) => {
    let avatar = item.ownerdetails[0].avatar;
    this.setState({ avatar: avatar })
  }
  getNicknameItemDetail = (item) => {
    let nickname = item.ownerdetails[0].name;
    this.setState({ nickname: nickname })
  }

  getDistance = (item) => {
    let R = 6371e3; // metres
    let φ1 = this.props.configuration.position.lat * Math.PI / 180; // φ, λ in radians
    let φ2 = item.location.coordinates[1] * Math.PI / 180;
    let Δφ = (item.location.coordinates[1] - this.props.configuration.position.lat) * Math.PI / 180;
    let Δλ = (item.location.coordinates[0] - this.props.configuration.position.lng) * Math.PI / 180;

    let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = (Math.round(d) / 1000);
    // return d.toFixed(0)/1000;
    return KM
  }

  getUrl = (item) => {
    let url = "";
    let userid = window.localStorage.getItem("userid");
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;

    url = url.replace("{APPNAME}", app);
    url = url.replace("{DOMAIN}", domain);
    url = url.replace("{USERID}", userid);


    let idparam=item.coverobj ? item.coverobj?._id : item.resources ? (item.resources.length > 0 ? item.resources[0]._id: "/images/Image 1_SMART.png") : "/images/Image 1_SMART.png";

    if (item.coverobj?.type === "image") {

      //sostituire  item.resources[0]?._id  con item.coverobj?._id
      url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + idparam; //eslint-disable-line

    } else {
      url = url + "&cover=true&medialan=en&mediares=first&" + "id=" + idparam; //eslint-disable-line
    }

    return url;
  }

  setCount = (x) => {
    this.setState({ count: x },
      //() => console.log(x)
    );
  }

  viewArrayMarker = (y) => {
    //console.log(y, y.length);
    this.props.viewArrayMarker(y);
  }


  render() {

    const { t } = this.props;


    // let green = new L.Icon({
    //   iconUrl: "/images/marker-icon-green.png",
    //   iconRetinaUrl: "/images/marker-icon-green.png",
    //   shadowUrl: "/images/marker-icon-green.png",
    //   iconSize: [45, 41],
    //   iconAnchor: [12, 41],
    //   popupAnchor: [1, -34],
    //   // shadowSize: [41, 41],
    //   shadowAnchor: null,
    //   className: 'marker-svg'
    // });


    // let greenIcon = new L.Icon({
    //   iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
    //   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    //   iconSize: [25, 41],
    //   iconAnchor: [12, 41],
    //   popupAnchor: [1, -34],
    //   // shadowSize: [41, 41],
    // });

    let blueIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      // shadowSize: [41, 41]
    });



    return (

      <div>
        <Suspense fallback={(<div>Loading</div>)}>
          <MapContainer
            // bounds={bounds}
            style={{
              height: `calc(100vh - 114px)`,
              width: "100vw",
              position: "fixed",
              margin: 0
            }}
            center={this.state.center}
            zoom={this.state.zoom}
            scrollWheelZoom
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />


            {this.props.items.map((item, i) => (

              <Marker
                item_id={item._id}
                className="marker-svg"
                key={i}
                position={this.getCoordinates(item)}
                // onClick={() => { this.openDialogDetail(item) }}
                // onClick={() => this.openSwipeableDrawer(item, i)}
                // item={item}
                // title={item.name}
                icon={item._id.toString() === (this.props.id.toString()) ? icon : blueIcon}
                eventHandlers={{
                  click: (e) => {
                    // console.log(e, e.target._leaflet_id, e.target._latlng, e.target);
                    this.props.getActiveStep(e.target.options.item_id)
                  },
                }}
              >
                {/*  <Popup autoPan={false}>
                  <h4>{item.name}</h4>
                 <div>

                    <img style={{
                      height: "100px",
                      width: "130px",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                      alt=""
                      src={this.getUrl(item)}
                      onClick={() => { this.openDialogDetail(item) }}
                    />
                  </div>
                </Popup>*/}
                {/* <Circle key="1" center={this.getCoordinates(item)} radius={3000} /> */}
              </Marker>
            ))}


            {/* <Marker position={this.state.center} icon={icon}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

            {/* <MultipleMarkers /> */}
            <LocationMarker alertMarker={this.openAlert} setCount={this.setCount} loadMarkers={this.viewArrayMarker} />
          </MapContainer>


          <Snackbar
            open={this.state.showAlert}
            autoHideDuration={1000}
            onClose={this.closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="warning"
              // action={
              //   <IconButton
              //     aria-label="close"
              //     color="inherit"
              //     size="small"
              //     onClick={this.closeAlert}
              //   >
              //     <CloseIcon fontSize="inherit" />
              //   </IconButton>
              // }

              style={{
                marginTop: "120px",
                maxWidth: "80vw",
              }}
            >

              {t('main.alert_itemsnotfound')}
            </Alert>
          </Snackbar>

        </Suspense>
        <DialogReportDetail
          open={this.state.openDialogDetail}
          close={() => this.closeDialogDetail()}
          itemDetail={this.state.itemDetail}
          kilometres={this.state.kilometres}
          cover={this.state.cover}
          avatar={this.state.avatar}
          nickname={this.state.nickname}
          badge={this.state.badge}
        />
      </div>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Leaflet));