import React, { Component } from "react";
import Header from "./Header";
import "./Login.css";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
// import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogForgot from "./Dialog/DialogForgot";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
//import { connect } from "react-redux";
import services from "../services/services.json";
import {
  postService,
  // getService
} from "../services/services";
// import {
//   WHOLE,
//   APP
// } from '../redux/actions';

import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/Wrapper';





const theme = createTheme({

  palette: {
    primary: {
      main: "#025148",
    },
    secondary: {
      main: "#ff7646",
    },
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      email: "",
      password: "",
      emailerror: false,
      passworderror: false,
      showAlert: false,
      domain:"",
      // selectDomain:""
    };
  }

  componentDidMount() {

    this.getDomains();

  }


  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailerror: (event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null ? true : false)
    });
  }


  handleChangePassword = (event) => {
    this.setState({
      password: event.target.value,
      passworderror: (event.target.value.length > 6 ? false : true)
    });
  }

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };

  getDomains = () => {
    let domainshortname = window.localStorage.getItem("domainshortname");
 
    this.setState({ domain: domainshortname })
  }


  signIn = () => {
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    // console.log(domain, app)

    var self = this;
    let params = {
      email: this.state.email,
      password: this.state.password,
      appname: app,
      domainId: domain
    }
    postService(process.env.REACT_APP_DOMAIN + services.login_POST, params,
      "", "",
      function (err, result) {

        if (err) {
          // console.log("login Error:  " + err);
          // alert("FAILED: Domain, Email or Password invalid!");
          self.openAlert();
        }
        else if (result.status === 201) {
          // console.log("login ok");
          // console.log(result.response);
          window.localStorage.setItem("userlogged", true);
          window.localStorage.setItem("userid", result.response.userId);
          window.localStorage.setItem("token", result.response.token);
          if (result.response.avatar) {
            window.localStorage.setItem("avatar", result.response.avatar);
          }

          window.localStorage.setItem("name", result.response.name);
          window.localStorage.setItem("role", result.response.role);
          window.localStorage.setItem("email", result.response.email);
          window.localStorage.setItem("status", result.response.status);
          window.localStorage.setItem("createdOn", result.response.createdOn);
          window.localStorage.setItem("uid", result.response.nodebb.uid);

          let url = "";

          url = "web-app/?app=" + app + "&domain=" + domain;
          //window.open(encodeURI(url), "_self", "_system", "location=no");     
          self.props.navigate('/' + url)
        }
      }
    );
  }

  openDialogPWD = () => {
    this.setState({ openDialog: true });
  };

  closeDialogPWD = () => {
    this.setState({ openDialog: false });
  };





  render() {
    const { t } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Suspense fallback={(<div>Loading</div>)}>
          <Header></Header>
          <div className="overlay">
            <div className="ui-panel">
              <div className="login-form">

                <Typography>
                  <span
                    style={{
                      fontFamily: "Mansalva",
                      fontSize: "30px",
                      color: "#004c43",
                    }}
                  >
                   {t('main.btn_login')}
                  </span>
                </Typography>

                <TextField
                  //inputProps={{ autoComplete: 'off' }}
                  id="outlined-required"
                  label={t('main.lbl_emailaddr')}
                  error={this.state.emailerror}
                  // fullWidth
                  onChange={this.handleChangeEmail}
                  name={t('main.lbl_emailaddr')}
                  type="text"
                  value={this.state.email}
                  sx={{ mt: 2 }}
                />
                <br />

                <TextField
                  id="outlined-password-input"
                  label={t('main.lbl_password')}
                  autoComplete="current-password"
                  // fullWidth
                  error={this.state.passworderror}
                  onChange={this.handleChangePassword}
                  name={t('main.lbl_password')}
                  type="password"
                  value={this.state.password}

                  sx={{ mt: 2 }}
                />


                <TextField
                  //inputProps={{ autoComplete: 'off' }}
                  disabled
                  id="outlined-required"
                  label={t('main.lbl_domain')}
                  // fullWidth
                  name={t('main.lbl_domain')}
                  type="text"
                  value={this.state.domain}
                  sx={{ mt: 2 }}
                />
                <br />


              </div>

              <footer>

                <div className="right">
                  <Button
                    variant="contained"
                    type='submit'
                    onClick={this.signIn}
                    disabled={(this.state.emailerror === false
                      && this.state.passworderror === false
                      && this.state.password.length > 6
                      //  && this.state.selectDomain!== ""
                      ? false
                      : true
                    )}
                  >{t('main.title_LOGIN')}</Button>
                </div>
                {/* <div className="left">
                <Button onClick={() => {
                  this.openDialogPWD();
                }}
                >
                  <span style={{textDecoration: "underline"}}>Forgot password?</span>
                  
                  </Button>


              </div> */}
                <br />
                <div className="left mt30"> 
                {t('main.lbl_msg_register')}&nbsp;&nbsp;&nbsp;
                <a href="/registration">{t('main.lbl_msg_registerNow')}</a>
                </div> <br />
                {/* <div className="left mt30">
                <Divider />
                <h6 style={{ textAlign: "justify" }}>By proceeding you agree to our Terms of Service. To find out
                  what personal data we use it, please visit our Privacy
                  Policy. For more information read our FAQs.</h6>
              </div> */}
              </footer>

              <DialogForgot
                open={this.state.openDialog}
                close={() => this.closeDialogPWD()}
              />

            </div>
          </div>


          <Snackbar
            open={this.state.showAlert}
            // autoHideDuration={4000}
            onClose={this.closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
             
              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
            }}
            >
              <Typography variant="button" display="block">
                <b> {t('main.dlgmsg_loginfailed')}</b><br />
                {t('main.dlgmsg_loginmes')}
              </Typography>
            </Alert>
          </Snackbar>

        </Suspense>
      </ThemeProvider>

    );
  }
}


export default (withTranslation())(withRouter(Login));
