import React, { Component } from 'react';
import './Menu.css';


class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }



  render() {

    return (
      <div>
        <div className="tmp"></div>
      </div>
    )
  }

}

export default Menu;
