import React, { Component } from 'react';

import  Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// import HelpIcon from '@mui/icons-material/Help';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogYesNo extends Component {
  constructor(props) {
  super(props);
  this.state={
    message: "titolo dialog",
    // info: "Do you want to submit this report?",
   // open:this.props.open
  };
  
   }


   onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;

    this.props.close();
     // this.setState({
         // open: !this.state.open
    //  })
    }

  render() {
   // let jsonlang = this.props.appstate.App.language;
  
   const { t } = this.props;
   return (
     <Suspense fallback={(<div>Loading</div>)}>
      <Dialog
        open={this.props.open}
        onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth = {'sm'}
        >
          {/* <DialogTitle >
            <HelpIcon
              aria-label="confirm Icon"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            /> 
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {this.state.message}
          </span>
        </DialogTitle> */}
        <DialogContent>
          {/* {this.state.info.toUpperCase()} */}
          {t('main.dlgmsg_confreportcreation')}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
            >
              <Typography >{t('main.btn_no')}</Typography>
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={ this.props.callback}
            color="primary">
            <Typography>{t('main.btn_yes')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
    </Suspense>
  )}
};

export default withTranslation()(DialogYesNo);