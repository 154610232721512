import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import WHOLE from "../redux/statecore";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
// import { getResourceDataForLanguage } from "../utils/utility";
import { createTheme } from "@mui/material/styles";
import DialogReportDetail from "./Dialog/DialogReportDetail";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import IconButton from '@mui/material/IconButton';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazy-load';
import "./ContentList.css";
import services from "../services/services.json";
import Skeleton from '@mui/material/Skeleton';


const theme = createTheme({

});

theme.typography.h4 = {
  fontSize: "1.4rem",
  "@media (min-width:600px)": {
    fontSize: "1.7rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.6rem",
  },
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  WHOLE: (payload) => dispatch(WHOLE(payload)),
});



class ContentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      setOpen: false,
      scrollY: false,
      openDialogDetail: false,
      itemDetail: [],
      positionenabled: false,
      lat: 0,
      lng: 0,
      kilometres: 0,
      cover: "",
      avatar: "",
      nickname: "",
      showAlert: false,
      items: this.props.items,
      badge: 0,
      loading: true,
    };
  }

  componentDidMount() {

    this.getLocationUser();
    // console.log(this.props.items)
    // this.setState({ loading: true });
  }


  getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition
      );
    } else {

    }
  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  };

  showPosition = (position) => {

    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });
    this.setState({ positionenabled: true });
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };


  handleScroll = (e) => {
    // console.log(e.deltaY);
    // e.preventDefault();
    if (e.deltaY < 15) {
      this.setState({ scrollY: true });
    } else {
      this.setState({ scrollY: false });
    }
  };

  checkDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Take the user to a different screen here.
      // console.log(navigator.userAgent)
      return "Mobile";
    } else return "Desktop";
  };

  getDistance = (item) => {
    // ============================================================
    // FROM: https://www.movable-type.co.uk/scripts/latlong.html
    // This uses the ‘haversine’ formula to calculate the great-circle distance between two points – that is, the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the points (ignoring any hills they fly over, of course!).
    // Haversine
    // formula: 	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
    // c = 2 ⋅ atan2( √a, √(1−a) )
    // d = R ⋅ c
    // where 	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
    // note that angles need to be in radians to pass to trig functions!
    // JavaScript:
    // const R = 6371e3; // metres
    // const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    // const φ2 = lat2 * Math.PI/180;
    // const Δφ = (lat2-lat1) * Math.PI/180;
    // const Δλ = (lon2-lon1) * Math.PI/180;
    //
    // const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
    // Math.cos(φ1) * Math.cos(φ2) *
    // Math.sin(Δλ/2) * Math.sin(Δλ/2);
    // const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    //
    // const d = R * c; // in metres
    // ============================================================

    let R = 6371e3; // metres
    let φ1 = (this.state.lat * Math.PI) / 180; // φ, λ in radians
    let φ2 = (item.location.coordinates[1] * Math.PI) / 180;
    let Δφ =
      ((item.location.coordinates[1] - this.state.lat) *
        Math.PI) /
      180;
    let Δλ =
      ((item.location.coordinates[0] - this.state.lng) *
        Math.PI) /
      180;

    let a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = Math.round(d) / 1000;
    // return d.toFixed(0)/1000;
    return KM;
  };


  openDialogDetail = (item) => {

    this.getkilometresItemDetail(item);
    this.getCoverItemDetail(item);
    this.getAvatarItemDetail(item);
    this.getNicknameItemDetail(item);
    this.getResourcesNumber(item);


    this.setState({ itemDetail: item }, () => this.open());

  }



  getResourcesNumber = (item) => {
    let badge = item.resources.length
    // console.log(badge)
    this.setState({ badge: badge })
  }


  getkilometresItemDetail = (item) => {
    let kilometres = this.getDistance(item).toFixed(2);
    this.setState({ kilometres: kilometres })
  }

  getCoverItemDetail = (item) => {
    let cover = this.urlResource(item);
    this.setState({ cover: cover })
  }
  getAvatarItemDetail = (item) => {
    let avatar = item.ownerdetails[0].avatar;
    this.setState({ avatar: avatar })
  }
  getNicknameItemDetail = (item) => {
    let nickname = item.ownerdetails[0].name;
    this.setState({ nickname: nickname })
  }

  open = () => {
    this.setState({ openDialogDetail: true });
  }

  closeDialogDetail = () => {
    this.setState({ openDialogDetail: false });
  };




  urlResource = (item) => {
    let url = "";
    let userid = window.localStorage.getItem("userid");
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;

    url = url.replace("{APPNAME}", app);
    url = url.replace("{DOMAIN}", domain);
    url = url.replace("{USERID}", userid);
    let idparam=item.coverobj ? item.coverobj?._id : item.resources ? (item.resources.length > 0 ? item.resources[0]._id: "/images/Image 1_SMART.png") : "/images/Image 1_SMART.png";
    if (item.coverobj?.type === "image") {
      
      //sostituire  item.resources[0]?._id  con item.coverobj?._id
      url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + idparam;// eslint-disable-line 

    } else {
      
      url = url + "&cover=true&medialan=en&mediares=first&" + "id=" + idparam ;// eslint-disable-line
    }

    return url;

  }

  

  getUTCData = (data) => {
    // console.log(data);
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short'
    }).format(new Date(data));
  }



  render() {


    const { t } = this.props;

    return this.props.progress === true ? (
      <CircularProgress
        sx={{
          p: 10,
        }}
      />
    ) : this.props.progress === false && this.props.items.length === 0 ? (
      <>
        <Typography
          sx={{
            p: 3,
          }}>
          <span 
            style={{
              fontFamily: "Mansalva",
              fontSize: "36px",
              color: "#004c43",
            }}
          >
            {t('main.alert_itemsnotfound')}
          </span>
        </Typography>
      </>
    ) : (

      <div >
        <Suspense fallback={(<div>Loading</div>)}>
          {this.props.items.length > 0 &&
            this.props.items.map((item, index) => (





              <div
                className="parentContainer" style={{
                  bottom: "0px",
                  position: "relative",
                  zIndex: "9",
                  display: (item.status !== "SOLVED" && item.status !== "APPROVED") ? "none" : ""
                }}>

                <LazyLoad height={"21vh"} threshold={0.5} onContentVisible={() => {
                  // console.log('loaded!'); 
                this.setState({ loading: false })
              }
                }>

                  {this.state.loading ? (
                    <Skeleton sx={{ height: 190, bgcolor: 'grey.900' }} animation="wave" variant="rectangular" />
                  ) : (
                    <img style={{
                      height: "20vh",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                      alt=""
                      //src={item.uriCoverExternalRes}
                      src={this.urlResource(item)}
                      onClick={() => { this.openDialogDetail(item) }}
                    />

                  )}
                </LazyLoad>






                <div className="top-right-content">

                  {item.ownerdetails[0].avatar !== undefined ?

                    <img alt="" className="img-user-content"
                      src={item.ownerdetails[0].avatar} />
                    :
                    <AccountCircleIcon style={{ fontSize: "50" }} />
                  }

                </div>

                <div className="bottom-left-incident">
                  <div className="incident-detail">
                    <div className="contentLeft">
                      <span className="cut">
                        <IconButton
                          style={{ padding: "1px" }}
                          onClick={() => {
                            this.openAlert();
                          }}
                        >
                          {item.status === "APPROVED" ?
                            <BookmarkIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                            :
                            item.status === "INSERTED" ?
                              <BookmarkAddIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                              :
                              item.status === "SOLVED" ?
                                <BookmarkAddedIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                :
                                item.status === "REJECTED" ?
                                  <BookmarkRemoveIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                  : <></>}
                        </IconButton>
                        {item.name}
                      </span>
                      <span
                        style={{
                          fontSize: "13px"
                        }}>{this.getUTCData(item.modifiedAt)}
                      </span>
                    </div>
                    <div className="contentRight">
                      <img
                        src={this.state.positionenabled === true ? "/images/itinerary.png" : "/images/itinerary-disabled-red.png"}
                        alt="background"
                        style={{
                          width: "32px",
                          marginLeft: "16px",
                          position: "relative",
                          top: "5px"
                        }}
                      ></img>
                      <span
                        style={{
                          fontSize: "13px",
                          visibility:
                            this.state.positionenabled === true
                              ? "visible"
                              : "hidden",
                        }}>
                        {this.getDistance(item).toFixed(2) + " km"}
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          <Snackbar
            open={this.state.showAlert}
            autoHideDuration={8000}
            onClose={this.closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
              }}
            >
              <Grid item xs={12}>
                {/* <Typography  variant="h6" component="div">
                Lifecycle
              </Typography> */}
                <>
                  <List>
                    <ListItem style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "8px",
                      paddingRight: "20px",
                    }}>
                      <ListItemAvatar>
                        <BookmarkAddIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={t('main.state_inserted')}
                      />
                    </ListItem>
                    <ListItem style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "8px",
                      paddingRight: "20px",
                    }}>
                      <ListItemAvatar>
                        <BookmarkIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={t('main.state_approved')}
                      />
                    </ListItem>
                    <ListItem style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "8px",
                      paddingRight: "20px",
                    }}>
                      <ListItemAvatar>
                        <BookmarkAddedIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={t('main.state_solved')}
                      />
                    </ListItem>
                    <ListItem style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "8px",
                      paddingRight: "20px",
                    }}>
                      <ListItemAvatar>
                        <BookmarkRemoveIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={t('main.state_rejected')}
                      />
                    </ListItem>
                  </List>
                </>
              </Grid>
            </Alert>
          </Snackbar>
          <>
            <DialogReportDetail
              open={this.state.openDialogDetail}
              close={() => this.closeDialogDetail()}
              itemDetail={this.state.itemDetail}
              kilometres={this.state.kilometres}
              cover={this.state.cover}
              avatar={this.state.avatar}
              nickname={this.state.nickname}
              badge={this.state.badge}
            />
          </>
        </Suspense>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContentList));
