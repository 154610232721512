import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const availableLanguages = ['en', 'it', 'es', 'gr', 'ca'];


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // .init({
  //   debug: true,
  //   fallbackLng: "en",
  // });

  .init({
    fallbackLng: 'en',
    supportedLngs: availableLanguages,
    debug: false,
    lng: window.localStorage.getItem('language'), // default language
    interpolation: {
      escapeValue: false,
    }
  });



export default i18n;