import React, { Component, useState } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  // useMap
} from 'react-leaflet';
import L from 'leaflet';

var LeafIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    //  shadowSize:   [50, 64],
    //  iconAnchor:   [22, 94],
    //  shadowAnchor: [4, 62],
    //  popupAnchor:  [-3, -76]
  }
});

var greenIcon = new LeafIcon({
  iconUrl: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
  // shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png'
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function LocationMarker({ savePosition, getPositionMarker, icon }) {

  // console.log("latlng:"+ getPositionMarker());  
  const [position, setPosition] = useState(null)

  const map = useMapEvents({

    click(e) {
      map.locate()
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
      // console.log("COOR:"+ e.latlng)
      savePosition(e.latlng)
    },

  })

  return position === null ?

    <Marker position={getPositionMarker()} icon={icon}>
      <Popup>You are here</Popup>
    </Marker>

    : (
      <Marker position={position} icon={icon}>
        <Popup>You are here</Popup>
      </Marker>
    )
}


class DialogMapAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0
    };

  }

  componentDidMount() {
    this.getLocationUser();
  }


  //function1 passata al MArker
  savePosition = (coor) => {
    // console.log(coor);
    this.setState({ lat: coor.lat });
    this.setState({ lng: coor.lng });

  }


  //function2 passata al MArker
  getPositionMarker = () => {
    return [this.state.lat, this.state.lng]
  }



  getLocationUser = () => {

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");//all'inizio e se non supportato bisogna posizionarsi a Roma
    }
  }

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  }

  showPosition = (position) => {
    // console.log(
    //   "Latitude: " + position.coords.latitude + "<br>" +
    //   "Longitude: " + position.coords.longitude);
    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });

  }


  save = () => {
    this.props.callback(this.state.lat, this.state.lng);
    this.props.closeSave();
  }


  onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    this.props.closeCancel();
  }




  render() {
    // let jsonlang = this.props.appstate.App.language;

    return (

      <Dialog
        open={this.props.open}
        // onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent sx={{ height: "80vh", overflow: 'hidden', padding: 0 }}>
          <MapContainer
            center={[this.state.lat, this.state.lng]}
            zoom={14}
            attributionControl={false}
            scrollWheelZoom={true}
            style={{ height: '80vh', width: '100wh' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker
              icon={greenIcon}
              getPositionMarker={this.getPositionMarker}
              savePosition={this.savePosition}
            />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
          >

            <Typography >CANCEL</Typography>
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={this.save}
            color="primary"
          >

            <Typography >SAVE</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
};

export default DialogMapAdd;
