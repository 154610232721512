import React, { Component } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
// import HelpIcon from '@mui/icons-material/Help';

import TextField from "@mui/material/TextField";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // message: "titolo dialog",
      // infoChange: "Are you sure you want to change the status?",
      // infoRemove: "Are you sure you want to cancel the report?",
      // open:this.props.open
      note: "",
      noteerror: false,
    };

  }

  handleChangeNote = (event) => {
    this.setState({ note: event.target.value, noteerror: (event.target.value.length > 2 ? false : true) });
  }


  onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }


  changeStatusConfirm = () => {
    this.props.callbackC(this.state.note);
    this.props.close();
  }

  removeReportConfirm = () => {
    this.props.callbackR();
    this.props.close();
  }

  render() {
    const { t } = this.props;
    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'sm'}
        >
          {/* <DialogTitle >
            <HelpIcon
              aria-label="confirm Icon"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            /> 
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {this.state.message}
          </span>
        </DialogTitle> */}
          <DialogContent>

            {this.props.itemDetail.status === "INSERTED" ?
              <>
                <span style={{
                  color: "#004c43",
                  fontWeight: 400,
                  fontSize: "1rem",
                  // fontFamily: 'Mansalva',
                }}>
                  {t('main.dlgmsg_confreportdeletetion')}
                </span>
              </>
              :
              this.props.itemDetail.status === "APPROVED" ?
                <>
                  <span style={{
                    color: "#004c43",
                    fontWeight: 400,
                    fontSize: "1rem",
                    // fontFamily: 'Mansalva',
                  }}>
                    {t('main.dlgmsg_confreportcreation')}
                  </span>
                  <TextField
                    multiline
                    style={{ marginTop: 30 }}
                    inputProps={{
                      // autoComplete: 'off',
                      maxLength: 120,
                    }}
                    variant="outlined"
                    label="Note"
                    // error={this.state.noteerror}
                    fullWidth
                    onChange={this.handleChangeNote}
                    name="note"
                    type="text"
                    value={this.state.note}
                    labelWidth={120}
                  />
                </>
                : <></>}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
            >

              <Typography >{t('main.btn_no')}</Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={this.props.itemDetail.status === "APPROVED" ? this.changeStatusConfirm : this.removeReportConfirm}
              color="primary"
            // startIcon={<BookmarkAddedIcon />}
            // disabled={(
            //   this.state.noteerror === false && this.state.note !== ""
            //     ? false : true)}
            >
              <Typography>{t('main.btn_yes')}</Typography>

            </Button>
          </DialogActions>
        </Dialog>
      </Suspense>
    )
  }
};


export default withTranslation()(DialogConfirm);