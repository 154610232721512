import React from 'react';
import './App.css';
import Home from "./components/Home";
import NotFound from "./components/NotFound";
// import Header from "./components/Header";
// import Add from "./components/Add";
// import Menu from "./components/Menu";
// import Saved from "./components/Saved";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Welcome from "./components/Welcome";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  // styled, 
  createTheme,
  ThemeProvider
} from '@mui/material/styles';

// import { Suspense } from 'react';
// import { useTranslation } from 'react-i18next';




const theme = createTheme({
  palette: {
    primary: {
      main: "#004c43"
    },
    secondary: {
      main: "#ffb639 "
    }
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#ffb639 !important",
            backgroundColor: "#004c43!important",
          },
          // selected: {
          //   "&&": {
          //     color: "#ffb639 !important",
          //     backgroundColor: "#004c43!important",
          //   }
          // },
          // "&:hover": {
            // color: '#000000',
            // backgroundColor: '#cecece'
          // },
          padding: "5px!important",
          color: "#004c43"
        },
      }
    },
    // MuiBottomNavigation: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "red",
    //     },
    //   },
    // },

    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "primary",
          "&.Mui-selected": {
            color: "#ffb639 !important",
            backgroundColor: "#004c43!important",
          },
          // selected: {
          //   "&&": {
          //     color: "#ffb639 !important",
          //     backgroundColor: "#004c43!important",
          //   }
          // },
          // "&:hover": {
            // color: '#000000',
            // backgroundColor: '#cecece'
          // },
        },
      },
    },
  //  $Muiselected: {
  //     styleOverrides: {
  //       label: {
  //         fontSize: "2px!important",
  //       },
  //     },
  //   },
    //   .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    //     margin-left: -1px;
    //     border-left: 1px solid #cf0e0e;
    //     border-top-left-radius: 0;
    //     border-bottom-left-radius: 0;
    // }

    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "0px!important"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    }

  }
});



function App() {
  // const { t, i18n } = useTranslation();

  return (

    // <Suspense fallback={(<div>Loading</div>)}>
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter >
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path="/" element={<Welcome />} />
            <Route path="/web-app" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            {/* 

            <Route path="/add" element={<Add />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/header" element={<Header />} />
            <Route path="/saved" element={<Saved />} /> 
            
            */}

          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
    // </Suspense>

  );
}

export default App;
