import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import "./DialogReportDetail.css";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import FilterIcon from '@mui/icons-material/Filter';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DialogImages from "../Dialog/DialogImages";
import DialogMapDistance from "../Dialog/DialogMapDistance";
import DialogConfirm from "../Dialog/DialogConfirm";
import DialogNodeBB from "../Dialog/DialogNodeBB";
import Lifecycle from '../Lifecycle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import services from "../../services/services.json";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import {
  postService, deleteService
} from "../../services/services";



const StyledAccordion = styled(Accordion)({
  '.MuiAccordionSummary-content': {
    justifyContent: "flex-end",
    marginRight: "10px",
  },
});



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // hashtags: [],
      openDialog: false,
      openDialogDistance: false,
      openDialogNodeBB: false,
      openDialogConfirm: false,
      positionenabled: false,
      itemDetail: this.props.itemDetail,
      lat: 0,
      lng: 0,
      url: "",
    };

  }

  componentDidMount() {
    // this.setState({ hashtags: getHashtags() });
    this.getLocationUser();


  }

  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.props.itemDetail !== this.state.itemDetail) {
      //  console.log(this.props.itemDetail)
      this.setState({ itemDetail: this.props.itemDetail })
    }


  }



  getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition
      );
    } else {
      // console.log("Geolocation is not supported by this browser."); 
    }
  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  };

  showPosition = (position) => {
    /* console.log(
       "Latitude: " +
       position.coords.latitude +
       "<br>" +
       "Longitude: " +
       position.coords.longitude
     );*/
    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });
    this.setState({ positionenabled: true });
  };

  onclose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.props.close();
  }

  openDialogImages = () => {
    this.setState({ openDialog: true });
  };
  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  openDialogNodeBB = (item) => {
    this.setState({ openDialogNodeBB: true });
  }
  closeDialogNodeBB = () => {
    this.setState({ openDialogNodeBB: false });
  };

  openDialogDistance = (item) => {
    this.setState({ openDialogDistance: true });
  }
  closeDialogDistance = () => {
    this.setState({ openDialogDistance: false });
  };

  openDialogConfirm = (item) => {
    this.setState({ openDialogConfirm: true });
  }
  closeDialogConfirm = () => {
    this.setState({ openDialogConfirm: false });
  };

  getUTCData = (data) => {
    try {
      return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "UTC",
        timeZoneName: "short",
      }).format(new Date(data));
    } catch (error) {
      return "";
      // console.log(error);
    }
  };

  openItemMap = () => {
    let latItem = this.state.itemDetail.location.coordinates[1] + ",";
    let lngItem = this.state.itemDetail.location.coordinates[0];

    let lat = this.state.lat + ",";
    let lng = this.state.lng;
    var mapLocationUrl =
      "https://maps.google.com/maps?saddr=" +
      lat +
      lng +
      "&daddr=" +
      latItem +
      lngItem;
    // }
    // console.log("mapLocationUrl:" + mapLocationUrl);
    window.open(encodeURI(mapLocationUrl), "_self", "_system", "location=no");
    // this.setState({urlDistance: mapLocationUrl});
  };

  openNodeBB = () => {
    // console.log(this.state.itemDetail.nodebb.response)
    // console.log(this.state.itemDetail.nodebb.response.tid)

    let url = "";
    let nodeBBTid = this.state.itemDetail.nodebb.response?.tid
    // let userLogin = window.localStorage.getItem("userlogged");
    let username = window.localStorage.getItem("name");
    let language = window.localStorage.getItem("language");
    let status = this.state.itemDetail.status;

    // console.log(username, status, nodeBBTid, language)


    // &noBottom=1&noTop=1 //eliminano header e footer di node BB dalla dialog


    // *************************************

    // indirizzo macchina AWS nuova dopo lo spegnimento della macchina fireware il 23.10.23
    // Il link di riferimento è:
    // https://nodebb-greenscent.net/BB/

    // Esempio:

    // https://nodebb-greenscent.net/BB/sessioneNEW.php?noTop=1&noBottom=1&langID=it&username=App1&mod%5B%5D=Verify_user_login&mod%5B%5D=get_topic_data&topicID=216

    // *************************************

    // let urlA = "https://cbroker-ba2know.eng.it/BB/sessioneNEW.php?langID=" + language + "&noBottom=1&noTop=1&username=";

    let urlA = "https://nodebb-greenscent.net/BB/sessioneNEW.php?noTop=1&noBottom=1&langID=" + language + "&noBottom=1&noTop=1&username=";


    let urlB = "&mod[]=Verify_user_login&mod[]=get_topic_data&topicID="

    if (status === "APPROVED") {
      if (username !== "") {

        url = urlA + username + urlB + nodeBBTid;

      } else {
        url = urlA + "Guest" + urlB + nodeBBTid;
      }
    }
    if (status === "SOLVED") {

      url = urlA + "Guest" + urlB + nodeBBTid;

    }
    if (!username) {

      url = urlA + "Guest" + urlB + nodeBBTid;

    }



    // console.log("URL" + url);
    this.setState({ url: url });

    this.openDialogNodeBB();

    // window.open(encodeURI(url), "_self", "_system", "location=no");


  };

  getSeverity = () => {

    let sev = this.state.itemDetail.gravity;
    // console.log("severity" + sev)
    let arr = [];


    if (sev === 0) {
      arr.push(<FiberManualRecordIcon sx={{ color:'#e5be01' }} />);
    }

    if (sev === 50) {
      arr.push(<FiberManualRecordIcon  sx={{ color:'#d87040' }} />);
      arr.push(<FiberManualRecordIcon  sx={{ color:'#d87040' }} />);
    }

    if (sev === 100) {
      arr.push(<FiberManualRecordIcon color="error"  />);
      arr.push(<FiberManualRecordIcon color="error"  />);
      arr.push(<FiberManualRecordIcon color="error"  />);

    }


    /*for (let i = 0; i < sev; i++) {
      arr.push(<FiberManualRecordIcon />);
    }*/
    // console.log("arr" + arr.length)

    return arr;
  }



  changeStatus = (note) => {
    // let self=this;
    let app = window.localStorage.getItem("currentapp");
    let domain = window.localStorage.getItem("currentdomain");

    let params = {
      status: "SOLVED",
      note: note,
      warningid: this.state.itemDetail._id,
      domain: domain,
      appname: app
    }

    postService(process.env.REACT_APP_DOMAIN + services.changestatus_POST, params,
      "", "",
      function (err, result) {

        if (err) {
          // console.log("changeStatus Error:  " + err);
        }

        else if (result.status === 200) {
          // console.log("changeStatus ok");
          // console.log(JSON.stringify(result.response));

          //exit dalla Dialog
          // self.props.close();
          let url = "";

          url = "web-app/?app=" + app + "&domain=" + domain;
          //window.open(encodeURI(url), "_self", "_system", "location=no");     
          window.location.href = ('/' + url)
        }

      });



  }



  removeReport = () => {
    let app = window.localStorage.getItem("currentapp");
    let domain = window.localStorage.getItem("currentdomain");

    let data = {
      appname: app,
      domain: domain,
      warningid: this.state.itemDetail._id
    }

    deleteService(process.env.REACT_APP_DOMAIN + services.removereport_DELETE, data,
      "",
      function (err, result) {

        if (err) {
          // console.log("removeReport Error:  " + err);
        }

        else if (result.status === 204) {
          // console.log("removeReport ok");
          // console.log(JSON.stringify(result));
          // console.log(JSON.stringify(result.response));


          let url = "";

          url = "web-app/?app=" + app + "&domain=" + domain;
          //window.open(encodeURI(url), "_self", "_system", "location=no");     
          window.location.href = ('/' + url)

        }

      });


  }

  getNodeBBAccess = () => {
    let flag = true;
    let status = this.state.itemDetail.status;

    //console.log(status);
    if (status === "APPROVED" || status === "SOLVED") {
      flag = false;
    } else {
      //console.log(flag);
    }


    return flag;
  }


  render() {
    const { t } = this.props;
    let lng = window.localStorage.getItem("i18nextLng");
    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}>
          <DialogContent sx={{ p: 0, overflow: "hidden" }}>
            <div>
              <img style={{
                height: "30vh",
                width: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
                alt=""
                // src={this.state.itemDetail.uriCoverExternalRes}
                src={this.props.cover}
              />
            </div>



            <StyledAccordion style={{ boxShadow: "unset", position: "unset", marginTop: "-15px", }}>
              <AccordionSummary
                expandIcon={<AccountTreeIcon style={{ color: "#004c43" }} />}
              >
                <Typography
                  // className="mansalva-h6" 
                  style={{ color: "#004c43" }}>workflow &nbsp;&nbsp;&nbsp;</Typography>
              </AccordionSummary>

              <Lifecycle itemDetail={this.state.itemDetail} />
            </StyledAccordion>



            <div className="under-wrap">
              {/* <div className="inner-avatar"> */}

              {this.props.avatar !== undefined && this.props.avatar !== "" && this.props.avatar !== null ?

                <img alt="" className="img-user-dialog" src={this.props.avatar} />
                :
                <AccountCircleIcon style={{ fontSize: "50" }} />
              }




              <Typography variant="overline" display="block" gutterBottom>
                <span>{this.props.nickname}</span>
                <span>{t('main.inserted')} {this.getUTCData(this.state.itemDetail.createdAt)}</span>
                <span>{t('main.lastupd')} {this.getUTCData(this.state.itemDetail.modifiedAt)}</span>
              </Typography>

            </div>

            <div className="center-title">
              <Typography variant="button" color="primary"
                style={{ fontWeight: 900 }}
              >
                {this.state.itemDetail.name}
              </Typography>
            </div>

            <div className="space-btw">
              <div className="hashBoxDetail">
                <ToggleButtonGroup>

                  {/* {this.state.itemDetail.length > 0 && this.state.itemDetail.tags.length > 0  && */}
                  {this.state.itemDetail !== [] && this.state.itemDetail.tags !== [] &&

                    this.state.itemDetail.tags?.map((item, i) => (
                      <ToggleButton
                        style={{ border: "0px" }}
                        className="hashAdd"
                        value={item.name[lng]}
                        aria-label="bold"
                      >
                        <Typography
                          className="mansalva-hasbuttons"
                        >
                          {item.name[lng]}
                        </Typography>
                      </ToggleButton>
                    ))}

                </ToggleButtonGroup>
              </div>


              {this.state.itemDetail.length > 0  
              && this.state.itemDetail.gravity !== undefined
              && this.state.itemDetail.gravity !== ""
                 ? <></> :



                <Box className="severity-left">

                  <Typography color="primary"
                    className="mansalva-h6"
                    style={{
                      paddingRight: "10px"
                    }}>
                    {t('main.severity')}
                  </Typography>

               
                  {this.getSeverity() }

                  <div style={{
                    paddingLeft: "10px"
                  }}>
                    {this.state.itemDetail.gravity === 0 ?
                      <Typography color="primary"
                        className="mansalva-h6"
                      >
                        {t('main.severity_low')}
                      </Typography> :
                      (this.state.itemDetail.gravity === 50 ?
                        <Typography color="primary"
                          className="mansalva-h6"
                        >
                          {t('main.severity_medium')}
                        </Typography> :
                        <Typography color="primary"
                          className="mansalva-h6"
                        >
                          {t('main.severity_high')}
                        </Typography>
                      )
                    }
                  </div>

                </Box>
              }

            </div>


            <div className="scroll-text">

              <Typography level="body2">
                {this.state.itemDetail.description}
              </Typography>


            </div>


          </DialogContent>

          {/* <div style={{ position: "fixed", top: 'auto', bottom: 60, textAlign: "justify" }}> */}
          <Box sx={{ display: 'flex', justifyContent: "space-around" }}>

            <IconButton
              color="primary"
              aria-label="all picture"
              component="label"
              onClick={() => {
                this.openDialogImages();
              }}
            >
              <Badge badgeContent={this.props.badge}
                color="secondary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                <FilterIcon style={{ fontSize: "2.5rem" }} />
              </Badge>
            </IconButton>


            <IconButton
              // onClick={() => { this.openDialogNodeBB() }}
              onClick={() => { this.openNodeBB() }}
              color="primary"
              aria-label="all messages"
              component="label"
              disabled={this.getNodeBBAccess()}
            >
              <Badge
                // badgeContent={16} 
                color="secondary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ChatBubbleOutlineIcon style={{ fontSize: "2.5rem" }} />
              </Badge>
            </IconButton>




            <IconButton
              disabled={this.state.positionenabled === true ? false : true}
              color="primary"
              aria-label="all messages"
              component="label">


              <img
                onClick={() => { this.openItemMap() }}
                src={this.state.positionenabled === true ? "../images/itinerary-primary.png" : "../images/itinerary-disabled-red.png"}
                alt="background"
                style={{
                  width: "50px",
                  // marginLeft: "16px",
                  // position: "relative",
                  // top: "5px",
                  // color: "primary",
                }}
              >
              </img>

              <span className='badge-km'
                style={{
                  visibility:
                    this.state.positionenabled === true
                      ? "visible"
                      : "hidden",
                }}
              >
                {this.props.kilometres} km
              </span>
            </IconButton>


          </Box>




          {this.props.valueNav === 2 ?

            <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
              {this.state.itemDetail.status === "APPROVED" ?
                <>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginLeft: "0px" }}
                    onClick={() => {
                      this.openDialogConfirm();

                    }}
                    disabled={
                      // this.state.itemDetail.length > 0  && this.state.itemDetail.tags !== undefined
                      this.state.itemDetail !== [] && this.state.itemDetail.tags !== undefined
                      && this.state.itemDetail.tags[0].name["it"] !== "#BestPractice" ? false : true}
                  >
                    <Typography>{t('main.btn_solved')}</Typography>
                  </Button>
                  {/*   <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.onclose}
                >
                  <Typography >CLOSE</Typography>
                </Button>*/}
                </>
                :
                this.state.itemDetail.status === "INSERTED" ?
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      style={{ marginLeft: "0px" }}
                      onClick={() => {
                        this.openDialogConfirm();
                      }}
                      disabled={
                        // this.state.itemDetail.length > 0 && this.state.itemDetail.tags !== undefined
                        this.state.itemDetail !== [] &&  this.state.itemDetail.tags !== undefined
                        && this.state.itemDetail.tags[0].name["it"] !== "#BestPractice" ? false : true}
                    >
                      <Typography>{t('main.btn_remove')}</Typography>
                    </Button>
                    {/*  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={this.onclose}
                  >
                    <Typography >CLOSE</Typography>
                  </Button>*/}
                  </>
                  : <></>}

              <>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.onclose}
                >
                  <Typography >{t('main.btn_close')}</Typography>
                </Button>
              </>

            </DialogActions>

            /*qui entra per valueNav===0*/
            :
            <DialogActions style={{ display: "flex", justifyContent: "" }}>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={this.onclose}
              >
                <Typography >{t('main.btn_close')}</Typography>
              </Button>
            </DialogActions>

          }

          <DialogImages
            open={this.state.openDialog}
            close={() => this.closeDialog()}
            itemDetail={this.state.itemDetail}
          />


          <DialogNodeBB
            open={this.state.openDialogNodeBB}
            close={() => this.closeDialogNodeBB()}
            NodeBBUrl={this.state.url}
          />

          <DialogMapDistance
            open={this.state.openDialogDistance}
            close={() => this.closeDialogDistance()}
            itemDetail={this.state.itemDetail}
          />

          <DialogConfirm
            open={this.state.openDialogConfirm}
            close={() => this.closeDialogConfirm()}
            itemDetail={this.state.itemDetail}
            callbackC={this.changeStatus}
            callbackR={this.removeReport}
          />

        </Dialog>

      </Suspense>
    )
  }
};

export default withTranslation()(DialogReportDetail);