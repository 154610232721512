import React, { Component } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



class DialogNodeBB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
      // item:this.props.itemDetail,
    };

  }

  componentDidMount() {
    this.getLocationUser();
    // this.openItemMap(this.props.itemDetail);

    // console.log(this.props.itemDetail);

  }




  getLocationUser = () => {

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");//all'inizio e se non supportato bisogna posizionarsi a Roma
    }
  }

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  }

  showPosition = (position) => {
    // console.log(
    //   "Latitude: " + position.coords.latitude + "<br>" +
    //   "Longitude: " + position.coords.longitude);
    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });

  }


  save = () => {
    this.props.callback(this.state.lat, this.state.lng);
    this.props.closeSave();
  }


  onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }

  openItemMap = (item) => {
    let latItem = item.location.coordinates[1] + ",";
    let lngItem = item.location.coordinates[0];

    // if (this.props.positionenabled === true) {
    //   var mapLocationUrl = "https://maps.google.com/maps?saddr=current location&daddr=" + latItem + lngItem;
    // } else {

    var mapLocationUrl =
      "https://www.google.com/maps/embed?saddr=" +
      this.state.lat +
      this.state.lng +
      "&daddr=" +
      latItem +
      lngItem;
    // }
    // console.log("mapLocationUrl:" + mapLocationUrl);
    window.open(encodeURI(mapLocationUrl), "_system", "location=yes");
  }


  render() {
    // let jsonlang = this.props.appstate.App.language;

    return (

      <Dialog
        open={this.props.open}
        onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent sx={{ height: "80vh", overflow: 'hidden', padding: 0 }}>
          {/* 
        <iframe src="http://217.172.12.150:8000/sessioneNEW.php?username=guest&mod[]=Verify_user_login&mod[]=get_topic_data&topicID=46" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border:"0px",height: "80vh", width:"80vw", margin:"5px"}} ></iframe> */}



          {/* <iframe
            title="nodebb"
            src={this.props.NodeBBUrl}
            style={{ border: "0px", height: "80vh", width: "80vw", margin: "5px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}


          <iframe src={this.props.NodeBBUrl} title="nodeBB" style={{ border: "0px", height: "79vh", width: "78vw", margin: "10px" }} >
          </iframe>


        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
          >

            <Typography >CLOSE</Typography>
          </Button>

          {/* <Button
            variant="contained"
            size="small"
            onClick={this.save}
            color="primary"
          >
            
              <Typography >SAVE</Typography>
          </Button> */}
        </DialogActions>
      </Dialog>
    )
  }
};

export default DialogNodeBB;
