export const initialState = {
  "app": "greenscent",
  "domain":"",
  "configuration": {
    "app": "greenscent",
    "title": "greenscent",
    "position": {
      lat:"41.8966277",
      lng:"12.4821685"
    },
    "Languages": {
      "default": "en",
      "list": [
        {
          "code": "en",
          "label": "EN - English",
          "icon": ""
        },
        {
          "code": "it",
          "label": "IT - Italiano",
          "icon": ""
        },
        {
          "code": "es",
          "label": "ES - Español",
          "icon": ""
        },
        {
          "code": "gr",
          "label": "GR - Ελληνικά",
          "icon": ""
        },
        {
          "code": "pl",
          "label": "PL - Polskie",
          "icon": ""
        }
      ]
    }
  }
};

export function statecore(state = initialState, action) {
  switch (action.type) {
    case 'WHOLE':
      return  Object.assign({}, state, action.payload);
    default:
      return state
  }
}





const appState = {
          App: {
            name: "greenscent",
            user:{
              logged: false,
              userid: "",
              token: "token di sessione",
              domain:"",
              name:"",
              role:"basic",
              email:"",
              status: "",
              avatar: "",
              createdOn:"",
              domainDetail:{}
            },
          
            currentLanguage:"",
            language:{
              login:"login",
              registration:"registration"
            }
            
        }
}

const statecoreapp = (state = appState, action) => {
  switch (action.type) {
    case "APP":
    return Object.assign({}, state, action.payload);
    default:
    return state;
  }
};

export default {

  appstate: statecoreapp
};

