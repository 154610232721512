import React, { Component } from "react";
import SourceIcon from '@mui/icons-material/Source';
import Typography from "@mui/material/Typography";
// import MenuIcon from "@mui/icons-material/Menu";
import AppBar from '@mui/material/AppBar';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ViewListIcon from '@mui/icons-material/ViewList';
import RoomIcon from '@mui/icons-material/Room';
import AddIcon from "@mui/icons-material/Add";
import Add from "./Add";
import Menu from "./Menu";
import Saved from "./Saved";
import Header from "./Header";
import "./Home.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import {
//   getHashtags,
//   getResources,
// } from "../utils/utility";

import ContentList from "./ContentList";
import MapList from "./MapList";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import WHOLE from "../redux/statecore";
import { connect } from "react-redux";

import services from "../services/services.json";
import { getService } from "../services/services";



const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  WHOLE: (payload) => dispatch(WHOLE(payload)),
});




class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHashtags: [],
      hashtags: [],
      items: [],
      progress: true,
      originalItems: [],
      itemforContent: [],
      showAlert: false,
      valueNav: 0,
      positionenabled: false,
    };
  }

  componentDidMount() {

    /*  this.setState({
        items: getResources(),
        originalItems: getResources(),
        itemforContent: getResources(),
        progress: false
      });*/

    this.getLocationUser();

    this.getResources();

    this.setState({ hashtags: this.getHashtags() });
  }

  componentDidUpdate() {
    //console.log("componentDidUpdate"+this.state.valueNav);
  }

  getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition
      );
    } else {

    }
  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  };

  showPosition = (position) => {
    this.setState({ positionenabled: true });
  };


  getResources = () => {
    let params = new URLSearchParams(window.location.search);
    let appname = params.get("app");
    let domainname = params.get("domain");
    window.localStorage.setItem("currentapp", appname);
    window.localStorage.setItem("currentdomain", domainname);
    // window.localStorage.setItem("language", "en");

    // console.log(appname, domainname);

    let self = this;
    let ser = process.env.REACT_APP_DOMAIN + services.reportlist_GET;
    ser = ser.replace("{APPNAME}", appname);
    ser = ser.replace("{DOMAIN}", domainname);
    // console.log(ser);


    getService(ser, "", function (err, result) {

      if (err) {
        // console.log("err " + err);
        return;
      }
      if (result.status === 200) {
        // console.log("Get ReportList ok");
        // console.log("get report list" + JSON.stringify(result.response));
        // console.log("reports" + result.response.length);

        self.setState({
          items: result.response,
          originalItems: result.response,
          itemforContent: result.response,
          progress: false
        });
        return;
      }
      if (result.status === 400) {
        //console.log(result.status)
        self.setState({ progress: false, items: [], originalItems: [], itemforContent: [] });
        //array vuoto
      }

      return;
    });
  };



  closeAlert = () => {
    this.setState({ showAlert: false });
  };



  getHashtags = () => {
   let app = window.localStorage.getItem("currentapp");
    var self = this;
    getService(process.env.REACT_APP_DOMAIN + services.tags_GET.replace("{APPNAME}", app), "", function (err, result) {
      if (err) {
        console.log("hashtags_GET Error");
      } else if (result.status === 200) {
        //   console.log("hashtags_GET OK");
        // console.log("response: "+JSON.stringify(result.response));
        self.setState({ hashtags: result.response },
          // () => console.log(JSON.stringify("RICEVO "+self.state.hashtags.length+" HASHTAGS"))
        );
      }
    }
    );

  }




  selectHashtags = (newValue) => {
    //  console.log(newValue);
    this.setState({ selectedHashtags: newValue }
      // ,() => console.log(JSON.stringify(this.state.selectedHashtags))
    );


    this.filterItemsByHashtags(newValue)

  }




  filterItemsByHashtags = (newValue) => {

    let lng = window.localStorage.getItem("i18nextLng");  

    let array_filtered = [];

    //console.log("NEWVALUE "+newValue)
    if (newValue.length > 0) {
      // console.log("----------------------------------------------");
      for (let i = 0; i < this.state.originalItems.length; i++) {
        // console.log(JSON.stringify(this.state.originalItems[i].tags));    
        for (const [key, value] of Object.entries(this.state.originalItems[i].tags)) { //eslint-disable-line
          // console.log("item.tag.hashtag" + JSON.stringify(value.name[lng]));
          if ((newValue).includes(value.name[lng])) {
            //elimina i doppioni
            if (!array_filtered.includes(this.state.originalItems[i]))
              array_filtered.push(this.state.originalItems[i]);

          }
        }
      }
      // console.log(JSON.stringify("array_filtered: " + array_filtered.length));
      this.setState({ itemforContent: array_filtered })
    }
    else this.setState({ itemforContent: this.state.items })
  }


  render() {
     let lng = window.localStorage.getItem("i18nextLng");
    // const { params, navigate } = this.props;
    let userLogin = window.localStorage.getItem("userlogged");
    const { t } = this.props;

    return (
      <>
        <Suspense fallback={(<div>Loading</div>)}>
          <Header />
          {this.state.valueNav === 0 ? (
            <>

              <AppBar className="appbarstyle2" position="fixed"
                style={{ backgroundColor: "#ffb639" }}>
                <div className="centerbkg">
                  <div
                    className="explore"
                  >
                    <Typography>
                      <span className="mansalva-explore"
                        // style={{
                        //   fontFamily: "Mansalva",
                        //   fontSize: "30px",
                        //   color: "#FFFFFF",
                        // }}
                      >
                        {t('main.title_HOME')}
                      </span>
                    </Typography>
                  </div>
                </div>

                <div className="scroll-category">
                  <ToggleButtonGroup
                    value={this.state.selectedHashtags}
                    onChange={(event, newValue) => { this.selectHashtags(newValue) }}
                  >
                    {
                      this.state.hashtags?.map((item, i) => (
                        <ToggleButton

                          className="hashHOME" value={item.name[lng]} aria-label="bold">
                          <Typography 
                          className="mansalva-hasbuttons"
                          >{item.name[lng]}</Typography>
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </div>
              </AppBar>
            </>
          ) : (
            <></>
          )}
          <div>
            {this.state.valueNav === 0 ? (
              <div className="appbarstyle3">
                <ContentList
                  items={this.state.itemforContent}
                  progress={this.state.progress}
                // positionenabled={this.state.positionenabled}
                />
              </div>
            ) : this.state.valueNav === 1 ? (
              <div className="appbarstyle4">
                <MapList items={this.state.items} />
              </div>
            ) : this.state.valueNav === 2 ? (
              <div className="appbarstyle4">
                <Saved items={this.state.items} valueNav={this.state.valueNav} />
              </div>
            ) : this.state.valueNav === 3 ? (
              <div className="appbarstyle4">
                <Add
                // setValueNav={(x)=>this.setState({ valueNav: x })} // **** DA VERIFICARE ANCHE IN ADD****
                />
              </div>
            ) : (
              <div className="appbarstyle4">
                <Menu />
              </div>
            )}
          </div>

          <AppBar position="fixed" style={{ top: 'auto', bottom: 0, textAlign: "justify" }}>
            <BottomNavigation
              showLabels
              value={this.state.valueNav}
              onChange={(event, newValue) => {
                this.setState({ valueNav: newValue });
              }}
              style={{
                backgroundColor: "#FFF",
              }}
            >
              <BottomNavigationAction
                label={t('main.mainpage_home')}
                icon={<ViewListIcon />}
              />
              <BottomNavigationAction
                label={t('main.mainpage_map')}
                icon={<RoomIcon />}
              />
              <BottomNavigationAction
                label={t('main.mainpage_myreport')}
                icon={<SourceIcon
                  color={(userLogin !== null && userLogin !== "false") ? "inherit" : "disabled"}
                />}
                disabled={(userLogin !== null && userLogin !== "false") ? false : true}
              />
              <BottomNavigationAction
              
                label={t('main.mainpage_new')}
                icon={<AddIcon
                  color={(userLogin !== null && userLogin !== "false") ? "inherit" : "disabled"}
                />}
                disabled={(userLogin !== null && userLogin !== "false" ) ? false : true}
              />
              {/* <BottomNavigationAction
              label="Menu"
              icon={<MenuIcon 
              color={(userLogin!==null && userLogin!=="false")? "primary" : "disabled"}
              />}
              disabled={(userLogin!==null && userLogin!=="false")? false : true}
            /> */}
            </BottomNavigation>
          </AppBar>
        </Suspense>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Home));