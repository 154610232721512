import React, { Component, useState } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



class DialogMapDistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
      item: this.props.itemDetail,
    };

  }

  componentDidMount() {
    this.getLocationUser();
    // this.openItemMap(this.props.itemDetail);

    // console.log(this.props.itemDetail);

  }




  getLocationUser = () => {

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");//all'inizio e se non supportato bisogna posizionarsi a Roma
    }
  }

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  }

  showPosition = (position) => {
    // console.log(
    //   "Latitude: " + position.coords.latitude + "<br>" +
    //   "Longitude: " + position.coords.longitude);
    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });

  }


  save = () => {
    this.props.callback(this.state.lat, this.state.lng);
    this.props.closeSave();
  }


  onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }

  openItemMap = (item) => {
    let latItem = item.location.coordinates[1] + ",";
    let lngItem = item.location.coordinates[0];

    // if (this.props.positionenabled === true) {
    //   var mapLocationUrl = "https://maps.google.com/maps?saddr=current location&daddr=" + latItem + lngItem;
    // } else {

    var mapLocationUrl =
      "https://www.google.com/maps/embed?saddr=" +
      this.state.lat +
      this.state.lng +
      "&daddr=" +
      latItem +
      lngItem;
    // }
    // console.log("mapLocationUrl:" + mapLocationUrl);
    window.open(encodeURI(mapLocationUrl), "_system", "location=yes");
  }


  render() {
    // let jsonlang = this.props.appstate.App.language;

    return (

      <Dialog
        open={this.props.open}
        onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent sx={{ height: "80vh", overflow: 'hidden', padding: 0 }}>

          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d772217.6768430664!2d15.029505270936168!3d40.883387688104364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x133baeb87abe8161%3A0xcce294e0724bcf9c!2sPomigliano%20d&#39;Arco%2C%20Viale%20Giuseppe%20Luraghi%2C%2080038%20Pomigliano%20D&#39;arco%20NA!3m2!1d40.914724299999996!2d14.393046499999999!4m5!1s0x13477ee2482b152b%3A0x8f6a4ae10da9360!2sMatera%2C%20MT!3m2!1d40.666379!2d16.6043199!5e0!3m2!1sit!2sit!4v1679064705133!5m2!1sit!2sit" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border:"0px",height: "80vh", width:"80vw", margin:"5px"}} ></iframe> */}


          <iframe
            src="https://www.google.com/maps/embed?saddr=40.9042944,14.3917056&daddr=40.899797844801455,14.569994707085028"
            style={{ border: "0px", height: "80vh", width: "80vw", margin: "5px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>



        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
          >

            <Typography >CLOSE</Typography>
          </Button>

          {/* <Button
            variant="contained"
            size="small"
            onClick={this.save}
            color="primary"
          >
            
              <Typography >SAVE</Typography>
          </Button> */}
        </DialogActions>
      </Dialog>
    )
  }
};

export default DialogMapDistance;
