import React, { Component } from 'react';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
// import HelpIcon from '@mui/icons-material/Help';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogRegistrationOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "titolo dialog",
      info: "messaggio dialog",
      // open:this.props.open
    };

  }


  onclose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }

  render() {
    // let jsonlang = this.props.appstate.App.language;
    const { t } = this.props;

    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          disableEscapeKeyDown
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'sm'}
        >

          <DialogContent>
            <Typography display="block">
              <span
                style={{
                  fontSize: "16px",
                  color: "#004c43",
                }}
              >
                <b>{t('main.dlgmsg_register')}</b><br /><br />
                {t('main.dlgmsg_registerinfo')}
              </span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
            >
              <Typography >{t('main.btn_close')}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Suspense>
    )
  }
};

export default (withTranslation())(DialogRegistrationOk);
