import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Component } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
// import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
// import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';


const StyledStepLabel = styled(StepLabel)({
    '.MuiStepLabel-label': {
        // color: '#d32f2f',
        color: '#004c43!important',
        // fontFamily: 'Bangers',
        marginTop: '0px',
        fontSize: '11px!important',
    }
});


const MYConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        padding: '0',
        marginTop: '0px',
        color: '#004c43',
        // fontFamily: 'Bangers',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ffb639',
            borderStyle: 'dotted',
            borderBlockEndWidth: 'inherit',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ffb639',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 4,
        borderRadius: 1,
        //   borderStyle: 'dotted',
        //   borderBlockEndWidth: 'inherit',
    },
}));

const MYStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 12,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#ffb639',
    }),
    '& .MYStepIcon-completedIcon': {
        color: '#004c43',
        zIndex: 1,
        fontSize: 26,
    },
    '& .MYStepIcon-circle': {
        width: 9,
        height: 9,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function MYStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <MYStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <CheckCircleIcon className="MYStepIcon-completedIcon" />
            ) : (
                <div className="MYStepIcon-circle" />
            )}
        </MYStepIconRoot>
    );
}



const inserted = ['TO BE APPROVED',
    <span style={{
        font: "small-caption",
        fontFamily: "sans-serif",
        fontStyle: "italic",
        fontSize: '14px',
        fontStretch: "condensed"
    }}>evaluation in progress</span>,]; //INSERTED -- connettore a {1}
const rejected = ['TO BE APPROVED', 'REJECTED']; //REJECTED -- connettore a {2}
const approved = ['TO BE APPROVED', 'APPROVED', 'SOLVED',]; //APPROVED -- connettore a {2} per Approved
const solved = ['TO BE APPROVED', 'APPROVED', 'SOLVED',]; //SOLVED -- connettore a {2} per Approved


class Lifecycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
        };
    }


    closeAlert = () => {
        this.setState({ showAlert: false });
    };

    openAlert = () => {
        this.setState({ showAlert: true });
    };



    getFlow = () => {

        switch (this.props.itemDetail.status) {

            case 'INSERTED':
                return (

                    <Stepper activeStep={1} connector={<MYConnector />} style={{ width: "100vw" }}>
                        {inserted.map((label) => (
                            <Step key={label}>
                                <StyledStepLabel
                                    StepIconComponent={MYStepIcon}
                                >{label}</StyledStepLabel>
                            </Step>
                        ))}
                    </Stepper>

                );
            case 'APPROVED':
                return (

                    <Stepper altnativeLabel activeStep={2} connector={<MYConnector />} style={{ width: "100vw" }}>
                        {approved.map((label) => (
                            <Step key={label}>
                                <StyledStepLabel
                                    StepIconComponent={MYStepIcon}
                                >{label}</StyledStepLabel>
                            </Step>
                        ))}
                    </Stepper>

                );
            case 'SOLVED':
                return (

                    <Stepper activeStep={3} connector={<MYConnector />} style={{ width: "100vw" }}>
                        {/* {solved.map((label, index) => {
                            const labelProps = {};
                            if (isCompleted(index)) {
                                labelProps.optional = (
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                this.openAlert();
                                            }}
                                        >
                                            <MilitaryTechIcon
                                                style={{ color: '#004c43' }}
                                            />
                                        </IconButton>
                                    </Box>
                                );

                                labelProps.info = false;
                            }

                            return (

                                <Step key={label} sx={{ display: 'inline' }}>
                                    <StyledStepLabel
                                        // StepIconComponent={MYStepIcon} 
                                        {...labelProps} >{label}</StyledStepLabel>
                                </Step>

                            );
                        })} */}
                        {solved.map((label) => (
                            <Step key={label}>
                                <StyledStepLabel
                                    StepIconComponent={MYStepIcon}
                                >{label}</StyledStepLabel>
                            </Step>
                        ))}
                    </Stepper>

                );
            case 'REJECTED':
                return (

                    <Stepper activeStep={2} connector={<MYConnector />} style={{ width: "100vw" }}>
                        {/* {rejected.map((label, index) => {
                            const labelProps = {};
                            if (isRejected(index)) {
                                labelProps.optional = (
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                this.openAlert();
                                            }}
                                        >
                                            <NoteAltIcon
                                            // style={{ fontSize: "18px", color: 'red', marginLeft:"50px" }} 
                                            />
                                        </IconButton>
                                    </Box>
                                );

                                labelProps.error = true;
                            }

                            return (

                                <Step key={label} sx={{ display: 'inline' }}>
                                    <StyledStepLabel {...labelProps} >{label}</StyledStepLabel>
                                </Step>

                            );
                        })} */}

                        {rejected.map((label) => (
                            <Step key={label}>
                                <StyledStepLabel
                                    StepIconComponent={MYStepIcon}
                                >{label}</StyledStepLabel>
                            </Step>
                        ))}
                    </Stepper>

                );

            default:
                return (
                    // <Stepper activeStep={1} connector={<MYConnector />} style={{ width: "100vw" }}>
                    //     {inserted.map((label) => (
                    //         <Step key={label}>
                    //             <StyledStepLabel
                    //                 StepIconComponent={MYStepIcon}
                    //             >{label}</StyledStepLabel>
                    //         </Step>
                    //     ))}
                    // </Stepper>
                    <></>
                );
        }

    }



    render() {
        const { t } = this.props;

        // console.log(JSON.stringify(this.props.itemDetail.note.length));

        return (
            <Box sx={{ width: '100vw', display: "flex" }}>
                <Suspense fallback={(<div>Loading</div>)}>
                    {this.getFlow()}


                    {(this.props.itemDetail.status === "REJECTED" || this.props.itemDetail.status === "SOLVED") && this.props.itemDetail.note.length > 0 ?
                        <IconButton
                            style={{ padding: "5px", background: "#ffb639", }}
                            onClick={() => {
                                this.openAlert();
                            }}
                        >
                            <MessageOutlinedIcon
                                style={{ color: "#000", fontSize: "22px" }}
                            />
                        </IconButton>
                        : <></>}

                    <Snackbar
                        open={this.state.showAlert}
                        autoHideDuration={8000}
                        onClose={this.closeAlert}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert
                            severity={this.props.itemDetail.status === "REJECTED" ? "warning" : "success"}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={this.closeAlert}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            style={{
                                marginBottom: "2px",
                                maxWidth: "80vw",
                            }}
                        >
                            <b>{t('main.lbl_note')} </b> {this.props.itemDetail.note}
                        </Alert>
                    </Snackbar>
                </Suspense>
            </Box>
        );
    }
}

export default withTranslation()(Lifecycle);
