import React, { Component } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import LanguageIcon from "@mui/icons-material/Language";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Badge from '@mui/material/Badge';
import DialogLanguage from "./Dialog/DialogLanguage";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { withRouter } from '../utils/Wrapper';
import './Header.css';

import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

// const LangLocale =   navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
// let i18nextLng = window.localStorage.getItem("i18nextLng");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenuInfoAvatar: false,
      lan:"", 
      lanpreconfig:"",
      openDialog: false,

    };
  }

  componentDidMount() {
    // console.log(i18nextLng);
  }


  componentDidUpdate(prevProps) {
  }

  renderMenu = (event) => {
    var thisRef = this;
    //thisRef.state.anchorEl = event.currentTarget;
    thisRef.setState({ openMenuInfoAvatar: true, anchorEl: event.currentTarget });
  }
  handleCloseInfoAvatar = () => {
    // this.state.anchorEl= null;
    this.setState({ openMenuInfoAvatar: false, anchorEl: null })
  }


  openDialogLang = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  getBadge = () => {
    // console.log(lan);
    // console.log(lanpreconfig);
    

    // this.setState({ lan: lan });
    // this.setState({ lanpreconfig: lanpreconfig });
     
   
  };


  goToLogin = () => {
    let url = "/Login"
    window.open(encodeURI(url), "_self");

  }

  logout = () => {
    // console.log("LOGOUT")
    //  let domain = window.localStorage.getItem("currentdomain");
    //  let app = window.localStorage.getItem("currentapp");

    var self = this;


    window.localStorage.setItem("userlogged", false);
    window.localStorage.setItem("userid", "");
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("avatar", "");
    window.localStorage.setItem("name", "");
    window.localStorage.setItem("role", "");
    window.localStorage.setItem("email", "");
    window.localStorage.setItem("status", "");
    window.localStorage.setItem("createdOn", "");
    this.setState({ openMenuInfoAvatar: false });

    // let url = "";
    // url = "web-app/?app=" + app + "&domain=" + domain;
    // console.log(url)
    self.props.navigate('/')
  }




  render() {
    let userLogin = window.localStorage.getItem("userlogged");
    //  let userid = window.localStorage.getItem("userid");
    let avatar = window.localStorage.getItem("avatar");

    // let app = window.localStorage.getItem("currentapp");
    let username = window.localStorage.getItem("name");
    //  console.log(userLogin, avatar)
    // let role = window.localStorage.getItem("role");
    let domainshortname = window.localStorage.getItem("domainshortname");

    let initLan=(window.localStorage.getItem('i18nextLng'))


    const { t } = this.props;

    return (
      <div>
        <Suspense fallback={(<div>Loading</div>)}>
          <Box sx={{ display: "flex" }}>
            {" "}
            {/*box che contiene icona drawer allineata a sinistra*/}
            <CssBaseline />
            <AppBar
              position="fixed"
              sx={{
                backgroundColor: "#FFFFFF",
                height: 'auto'
              }}
            >
              <Toolbar>
                <Link component={RouterLink} to="/">
                  <Box
                    component="img"
                    sx={{
                      height: 50,
                    }}
                    alt="logo ideha"
                    src="/images/logo.png"
                  />
                </Link>

                <Box display="flex" flexGrow={1}>
                  {/* //sinistra */}
                </Box>

                {/*-------------AVATAR-----------*/}

                {userLogin === "true" ?
                  <div
                  // onClick={this.logout}
                  >
                    <Avatar
                      alt={username.toUpperCase()}
                      src={avatar}
                      onClick={this.renderMenu}
                    />
                  </div>
                  :
                  <IconButton
                    color="primary"
                    size="small"
                    aria-label="Login"
                    onClick={this.goToLogin}
                  // disabled={}
                  >
                    <LockIcon style={{ fontSize: "30px" }} />
                  </IconButton>
                }


                {/*-------------LANGUAGE-----------*/}


                <IconButton
                  sx={{
                    marginLeft: 1,
                  }}
                  color="primary"
                  aria-label="Language"
                  onClick={() => {
                    this.openDialogLang();
                  }}
                >
                  <Badge badgeContent={initLan} color="primary">
                    <LanguageIcon style={{ fontSize: "30px" }} />
                  </Badge>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Popover
              open={this.state.openMenuInfoAvatar}
              anchorEl={this.state.anchorEl}
              onClose={this.handleCloseInfoAvatar}

              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className="info">
                <div className="left-close">
                  <IconButton aria-label="close">
                    <CloseIcon
                      sx={{ fontSize: "22px" }}
                      onClick={this.handleCloseInfoAvatar}
                    />
                  </IconButton>
                </div>
                <section className="left-column">
                  <div>
                    <Typography
                      variant="subtitle1"
                      className="bold"
                    >
                      {t('main.lbl_name')}:
                    </Typography>
                  </div>
                </section>
                <section className="right-column">
                  <div>
                    <Typography
                      variant="subtitle1">
                      {username}
                    </Typography>
                  </div>
                </section>
                <section className="left-column">
                  <div>
                    <Typography
                      variant="subtitle1"
                      className="bold"
                    >
                      {t('main.lbl_domain')}:
                    </Typography>
                  </div>
                </section>
                <section className="right-column">
                  <div>
                    <Typography variant="subtitle1"
                    >
                      {domainshortname}
                    </Typography>
                  </div>
                </section>
                <div className="info-button">
                  <Button
                    size="small"
                    variant='contained'
                    color="primary"
                    startIcon={<ExitToAppIcon />}
                    onClick={this.logout}
                  >
                    {t('main.btn_logout')}
                  </Button>
                </div>
              </div>
            </Popover>
          </Box>
          <>
            <DialogLanguage
              open={this.state.openDialog}
              close={() => this.closeDialog()}
              // callback={this.getBadge}         
            />
          </>
        </Suspense>
      </div>

    );
  }
}

export default (withTranslation())(withRouter(Header));