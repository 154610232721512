import React, { Component } from 'react';
import Leaflet from './Leaflet';
// import ListScrollToItem from './ListScrollToItem';
import WHOLE from "../redux/statecore";
import { connect } from "react-redux";
// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
// import {
//   CardActionArea, CardActions,
// } from '@mui/material';
// import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DialogReportDetail from "./Dialog/DialogReportDetail";
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import IconButton from '@mui/material/IconButton';
// import {
//   getResourceDataForLanguage,
// } from "../utils/utility";
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import services from "../services/services.json";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  WHOLE: (payload) => dispatch(WHOLE(payload))
});


class MapList extends Component {

  constructor(props) {
    super(props);

    this.child = React.createRef();
    // this.scrollableListRef = React.createRef();

    // this.scrollableListRef =this.props.items.reduce((acc, value) => {
    //     acc[value._id] = React.createRef();
    //     return acc;
    //   }, {});
    //
    // console.log(this.scrollableListRef)

    this.state = {
      //openSwipeableDrawer: false,
      // singleItem: {},
      disabledLeft: false,
      disabledRight: false,
      activeStep: 0,
      itemsView: this.props.items,
      lat: parseFloat(this.props.configuration.position.lat),
      lng: parseFloat(this.props.configuration.position.lng),
      open: false,
      setOpen: false,
      scrollY: false,
      openDialogDetail: false,
      itemDetail: [],
      positionenabled: false,
      kilometres: 0,
      cover: "",
      avatar: "",
      nickname: "",
      showAlert: false,
      badge: 0,
      itemsViewInBound: [],
      id: ""
    }
  }

  componentDidMount() {
    // console.log(this.props.items);
    this.filter();
  }

  componentDidUpdate(propsPrecedenti) {
    // // Utilizzo tipico (non dimenticarti di comparare le props):
    // // console.log(propsPrecedenti.configuration.position.lat);
    // // console.log(this.props.configuration.position.lat);
    // // console.log(this.state.center.lat);
    // // this.props.configuration.position.lat
    // if (this.state.lat !== parseFloat(propsPrecedenti.configuration.position.lat) ||
    //   this.state.lng !== parseFloat(propsPrecedenti.configuration.position.lng)) {
    //   // console.log("centro cambiato");
    //   this.setState({
    //     lat: parseFloat(propsPrecedenti.configuration.position.lat) ? parseFloat(propsPrecedenti.configuration.position.lat) : 0,
    //     lng: parseFloat(propsPrecedenti.configuration.position.lng) ? parseFloat(propsPrecedenti.configuration.position.lng) : 0,
    //     itemsView: this.props.items,

    //   })

    // }
  }

  filter = () => {
    let arr = this.props.items;
    let arr1 = [];
    // console.log("arrayiniziale" + JSON.stringify(arr));

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status === "APPROVED" || arr[i].status === "SOLVED") {
        arr1.push(arr[i]);

      }
      this.setState({ itemsView: arr1 },
        //  () => console.log("array con solo solved" + this.state.itemsView)
      );

    }
  }


  open = () => {
    this.setState({ openDialogDetail: true });
  }

  openDialogDetail = (item) => {

    this.getkilometresItemDetail(item);
    this.getCoverItemDetail(item);
    this.getAvatarItemDetail(item);
    this.getNicknameItemDetail(item);
    this.getResourcesNumber(item);

    this.setState({ itemDetail: item }, () => this.open());

  }

  getResourcesNumber = (item) => {
    let badge = item.resources.length
    // console.log(badge)
    this.setState({ badge: badge })
  }

  getkilometresItemDetail = (item) => {
    let kilometres = this.getDistance(item).toFixed(2);
    this.setState({ kilometres: kilometres })
  }

  getCoverItemDetail = (item) => {
    let cover = this.getUrl(item);
    this.setState({ cover: cover })
  }
  getAvatarItemDetail = (item) => {
    let avatar = item.ownerdetails[0].avatar;
    this.setState({ avatar: avatar })
  }
  getNicknameItemDetail = (item) => {
    let nickname = item.ownerdetails[0].name;
    this.setState({ nickname: nickname })
  }

  closeDialogDetail = () => {
    this.setState({ openDialogDetail: false });
  };

  // toggleDrawer = (status) => {
  //   this.setState({ openSwipeableDrawer: status });
  // }

  // openItemDrawer = (item, index) => {
  //   // console.log(">>> ITEM: "+JSON.stringify(item));
  //   // console.log("openItemDrawer "+item.name);
  //   this.placeSelectedItemInTheMiddle(index, item);
  //   this.setState({ singleItem: item }, this.toggleDrawer(true));
  // }

  getUrl = (item) => {

    // console.log(JSON.stringify(item.name));
    let url = "";
    let userid = window.localStorage.getItem("userid");
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;

    url = url.replace("{APPNAME}", app);
    url = url.replace("{DOMAIN}", domain);
    url = url.replace("{USERID}", userid);

    let idparam=item.coverobj ? item.coverobj?._id : item.resources ? (item.resources.length > 0 ? item.resources[0]._id: "/images/Image 1_SMART.png") : "/images/Image 1_SMART.png";


    if (item.coverobj?.type === "image") {

      //sostituire  item.resources[0]?._id  con item.coverobj?._id
      url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + idparam; //eslint-disable-line

    } else {
      url = url + "&cover=true&medialan=en&mediares=first&" + "id=" + idparam; //eslint-disable-line
    }

    return url;
  }

  getDistance = (item) => {
    // console.log("getDistance "+JSON.stringify(item.location));
    // console.log("ROMA lat x2: "+this.props.configuration.position.lat);
    // console.log("ROMA lng y2: "+this.props.configuration.position.lng);
    // let x2=this.props.configuration.position.lat;
    // let y2=this.props.configuration.position.lng;
    // let x1=item.location.coordinates[1];
    // let y1=item.location.coordinates[0];
    // let y = x2 - x1;
    // let x = y2 - y1;
    //
    // return Math.sqrt(x * x + y * y);


    // ============================================================
    // FROM: https://www.movable-type.co.uk/scripts/latlong.html
    // This uses the ‘haversine’ formula to calculate the great-circle distance between two points – that is, the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the points (ignoring any hills they fly over, of course!).
    // Haversine
    // formula: 	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
    // c = 2 ⋅ atan2( √a, √(1−a) )
    // d = R ⋅ c
    // where 	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
    // note that angles need to be in radians to pass to trig functions!
    // JavaScript:
    // const R = 6371e3; // metres
    // const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    // const φ2 = lat2 * Math.PI/180;
    // const Δφ = (lat2-lat1) * Math.PI/180;
    // const Δλ = (lon2-lon1) * Math.PI/180;
    //
    // const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
    // Math.cos(φ1) * Math.cos(φ2) *
    // Math.sin(Δλ/2) * Math.sin(Δλ/2);
    // const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    //
    // const d = R * c; // in metres
    // ============================================================


    let R = 6371e3; // metres
    let φ1 = this.props.configuration.position.lat * Math.PI / 180; // φ, λ in radians
    let φ2 = item.location.coordinates[1] * Math.PI / 180;
    let Δφ = (item.location.coordinates[1] - this.props.configuration.position.lat) * Math.PI / 180;
    let Δλ = (item.location.coordinates[0] - this.props.configuration.position.lng) * Math.PI / 180;

    let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = (Math.round(d) / 1000);
    // return d.toFixed(0)/1000;
    return KM
  }





  truncate = (str, max) => {
    return str.length > max ? str.substr(0, max - 1) + '…' : str;
  }

  handleNext = () => {
    // console.log(this.state.activeStep);
    this.setState({ activeStep: this.state.activeStep + 1 }
      //,  () =>console.log(this.state.activeStep)
    );

    this.getItemId(this.state.activeStep + 1);
  };

  handleBack = () => {
    // console.log(this.state.activeStep);
    this.setState({ activeStep: this.state.activeStep - 1 });
    this.getItemId(this.state.activeStep - 1);
  };


  getItemId = (act) => {
    var item_id = "";
    for (let i = 0; i < this.state.itemsViewInBound.length; i++) {
      // console.log(i, act)
      if (i.toString().includes(act.toString())) {
        // console.log("matcha")
        item_id = this.state.itemsViewInBound[i]._id;
        // console.log(item_id);
        this.setState({ id: item_id }, 
          // () => console.log(this.state.id)
          )
        return;
      }
    }
  }

  handleStepChange = (event) => {
  //  console.log(event);

    this.setState({ activeStep: event });
    this.getItemId(event);
  };

  // loadMarkers = (items) => {
  //   this.setState({ 
  //     itemsView: items, 
  //     activeStep: 0, 
  //     // singleItem: {} 
  //   });
  // }

  viewArrayMarker = (y) => {
    let arrapp = []

    for (let i = 0; i < y.length; i++) {


      for (let j = 0; j < this.state.itemsView.length; j++) {
        //console.log(this.state.itemsView[j]._id, y[i])

        if (this.state.itemsView[j]._id === y[i]) {

          arrapp.push(this.state.itemsView[j])
        }
      }

    }

    this.setState({
      itemsViewInBound: arrapp,
      activeStep: 0,
      //id: arrapp[0]._id  
      id: ""
    },
      // () => console.log("itemsViewInBound after :" + JSON.stringify(this.state.itemsViewInBound.length))
    );



    if (arrapp.length > 0) {
      this.setState({ id: arrapp[0]._id })
    }
    else {
      this.setState({ id: "" })
    }

  }

  getActiveStep = (z) => {
    // console.log(z);
    // var act = "";
    for (let i = 0; i < this.state.itemsViewInBound.length; i++) {
      // console.log(i, act)
      if (this.state.itemsViewInBound[i]._id === z) {
        // console.log("matcha")

        this.setState({ activeStep: i, id: this.state.itemsViewInBound[i]._id }, 
          // () => console.log(this.state.activeStep)
          )
        return;
      }
      // else
      // console.log("non matcha")
    }

  }

  render() {
    const { t } = this.props;


    return (
      <>
        <Leaflet
          items={this.state.itemsView}
          viewArrayMarker={this.viewArrayMarker}
          id={this.state.id}
          getActiveStep={this.getActiveStep}
        />
        <Suspense fallback={(<div>Loading</div>)}>
          {this.state.itemsView.length === 0 ?
            <>
              <Typography>
                <span
                  style={{
                    width: "80vw",
                    left: 30,
                    // fontFamily: "Bangers",
                    fontSize: "36px",
                    color: "#004c43",
                    zIndex: 100,
                    position: "absolute",
                    backgroundColor: "#ffffff7a",
                  }}
                >
                  {t('main.alert_itemsnotfound')}
                </span>
              </Typography>
            </>
            :
            <>

              <Box sx={{
                bottom: "5%",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                position: 'absolute',
                width: "100%",
                // m:2,

                // paddingBottom:"33px",
              }}>
                <SwipeableViews
                  style={{
                    width: "250px",
                    display: "inline-block"
                  }}
                  index={this.state.activeStep}
                  onChangeIndex={this.handleStepChange}
                  enableMouseEvents>
                  {this.state.itemsViewInBound.length > 0 && this.state.itemsViewInBound.map((item, index) => (
                    <div
                      style={{
                        height: "20vh",
                        overflow: "hidden",
                        backgroundColor: "white",
                        bottom: "0px",
                        position: "relative",
                        zIndex: "9"
                      }}
                    >
                      <img style={{
                        height: "20vh",
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                        alt="cover"
                        src={this.getUrl(item)}
                        onClick={() => { this.openDialogDetail(item) }}
                      />
                    </div>
                  ))}
                </SwipeableViews>
                {this.state.itemsViewInBound.length > 0 &&
                  <MobileStepper
                    variant="text"
                    steps={this.state.itemsViewInBound.length}
                    position="static"
                    activeStep={this.state.activeStep}
                    style={{
                      // width:"250px"
                      margin: "5px 15px 30px 15px",

                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={this.handleNext}
                        disabled={this.state.activeStep === this.state.itemsViewInBound.length - 1}
                      >
                        {t('main.arrow_forward')}
                        {window.document.dir === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                        {window.document.dir === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        {t('main.arrow_back')}
                      </Button>
                    }
                  />
                }
              </Box>

              <DialogReportDetail
                open={this.state.openDialogDetail}
                close={() => this.closeDialogDetail()}
                itemDetail={this.state.itemDetail}
                kilometres={this.state.kilometres}
                cover={this.state.cover}
                avatar={this.state.avatar}
                nickname={this.state.nickname}
                badge={this.state.badge}
              />
            </>
          }
        </Suspense>
      </>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MapList));