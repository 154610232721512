import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import DeleteIcon from "@mui/icons-material/Delete";
import Radio from "@mui/material/Radio";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PlaceIcon from "@mui/icons-material/Place";
import "./Add.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DialogYesNo from "./Dialog/DialogYesNo";
import DialogMapAdd from "./Dialog/DialogMapAdd";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import services from "../services/services.json";
import {
  postService,
  getService,
  multipartformService,
} from "../services/services";

import { withRouter } from '../utils/Wrapper';



const StyledFCL = styled(FormControlLabel)({
  '.MuiFormControlLabel-label': {
    // color: '#d32f2f',
    color: '#004c43',
    // fontFamily: "mansalva",
  }
});

var countUpl = 0;

class Add extends Component {
  constructor(props) {
    super(props);

    this.refFileinput = null;
    this.setRefFileinput = element => {
      this.refFileinput = element;
    };

    this.refFileinputClick = () => {
      if (countUpl < 3) {
        if (this.refFileinput) {
          this.refFileinput.click();
        }

      } else {
        // alert("Max number of upload reached!!!");
        this.openAlertCounter();
      }

    }


    this.state = {
      hashtags: [],
      selectedValueCover: 0,
      openDialog: false,
      openDialogMap: false,
      selectedHashtags: [],
      title: "",
      description: "",
      lat: 0,
      lng: 0,
      currentActive: false,
      mapActive: false,
      positionenabled: false,
      images: [],
      index: 0,
      showAlertSize: false,
      showAlertSExtension: false,
      showAlertCounter: false,
      showAlertGPS:false,
      cameraImage: [],
      file: undefined,
      filename: "",
      blobCameraImage: [],
      arrayId: [],
      gravity: "0", // 0=low, 50=medium, 100=high 
      LenghtTitleError: false,
      LenghtDescriptionError: false,



      form: {
        appname: window.localStorage.getItem("currentapp"),
        userId: window.localStorage.getItem("userid"),
        name: "",
        description: "",
        dest: "resource",
        isExtRes: false,
      },
      coverId: "",
      coverName: "",
      loader: false,
    };
  }




  init = () => {
    this.setState({

      form: {
        appname: window.localStorage.getItem("currentapp"),
        userId: window.localStorage.getItem("userid"),
        name: "",
        description: "",
        dest: "resource",
        isExtRes: false,
      },
      file: undefined
    });
  }



  componentDidMount() {
    this.checkGPS();
    this.setState({ hashtags: this.getHashtags() });
  }


  getHashtags = () => {
    let app = window.localStorage.getItem("currentapp");
     var self = this;
     getService(process.env.REACT_APP_DOMAIN + services.tags_GET.replace("{APPNAME}", app), "", function (err, result) {
       if (err) {
         console.log("hashtags_GET Error");
       } else if (result.status === 200) {
         //   console.log("hashtags_GET OK");
         // console.log("response: "+JSON.stringify(result.response));
         self.setState({ hashtags: result.response },
           // () => console.log(JSON.stringify("RICEVO "+self.state.hashtags.length+" HASHTAGS"))
         );
       }
     }
     );
 
   }

  handleChangeSeverity = (event) => {
    // console.log("SEVERITY: " + event.target.value);
    this.setState({
      gravity: event.target.value
    });
  }

  handleChangeCover = (index) => {
    // console.log("la cover e' alla posizione" + index);
    this.setState({ selectedValueCover: index, coverName: this.state.cameraImage[index].name },
      // () => console.log(this.state.coverName)
    );

  };

  deleteUploadFile = (index) => {
    // console.log("posizione card da cancellare: " + index);
    // console.log("selectedValue: " + this.state.selectedValueCover)

    let arr = this.state.cameraImage;
    // console.log(arr);
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === arr[index]) {
        arr.splice(i, 1);
      }
    }
    let arr2 = this.state.blobCameraImage;
    for (var j = 0; j < arr2.length; j++) {
      if (arr2[j] === arr2[index]) {
        arr2.splice(j, 1);
      }
    }

   //cancello l'elemento che è anche cover
    if (index === this.state.selectedValueCover && arr.length > 0) {
      this.setState({ selectedValueCover: 0, coverName: arr[0].name },
        // () => console.log(this.state.coverName)
      );
    }

//cancello l'elemento che non è cover, con selectedValueCover!==0
    if (index !== this.state.selectedValueCover  && this.state.selectedValueCover>0   && arr.length > 0) {
      this.setState({ selectedValueCover: this.state.selectedValueCover - 1, coverName: arr[this.state.selectedValueCover - 1].name },
        // () => console.log(this.state.coverName)
      );
    }

    this.setState({ cameraImage: arr, blobCameraImage: arr2 });
    countUpl--;

  };

  openDialogYoN = () => {
    this.setState({ openDialog: true });
  };

  openMapAdd = () => {
    this.setState({ openDialogMap: true });
  };

  closeAlertSize = () => {
    this.setState({ showAlertSize: false });
  };

  openAlertSize = () => {
    this.setState({ showAlertSize: true });
  };

  openAlertExtension = () => {
    this.setState({ showAlertExtension: true });
  };

  closeAlertExtension = () => {
    this.setState({ showAlertExtension: false });
  };

  closeAlertCounter = () => {
    this.setState({ showAlertCounter: false });
  };

  openAlertCounter = () => {
    this.setState({ showAlertCounter: true });
  };

  closeAlertGPS = () => {
    this.setState({ showAlertGPS: false });
  };

  openAlertGPS = () => {
    this.setState({ showAlertGPS: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  closeSaveMapAdd = () => {
    this.setState({ openDialogMap: false });
    this.setState({ currentActive: false });
    this.setState({ mapActive: true });
  };

  closeCancelMapAdd = () => {
    this.setState({ openDialogMap: false });

  };



  selectHashtags = (newValue) => {
    // console.log(newValue);

    this.setState({ selectedHashtags: newValue },
      // () =>console.log(JSON.stringify(this.state.selectedHashtags))
    );
  };



  // checkforBestPractice = (item) => {
   
  //   let flag = false;

  //   if (this.state.selectedHashtags.length === 0) {
  //     flag = false
  //   };



  //   if (this.state.selectedHashtags.length === 1 && this.state.selectedHashtags[0] === "#BestPractice") {
  //     if (item.name['it'] === "#BestPractice") {
  //       flag = false
  //     } else {
  //       flag = true
  //     }
  //   }
  //   if (this.state.selectedHashtags.length >= 1 && this.state.selectedHashtags[0] !== "#BestPractice") {
  //     if (item.name['it'] === "#BestPractice") {
  //       flag = true
  //     }
  //     else {
  //       flag = false
  //     }
  //   }

  //   return flag;
  // }


  changeTitle = (event) => {
    if (event.target.value.toString().length <= 30) {
      this.setState({
        title: event.target.value,
        LenghtTitleError: false,
      })
    }
    if (event.target.value.toString().length < 3) {
      this.setState({
        title: event.target.value,
        LenghtTitleError: true,
      })
    }
  };

  changeDescription = (event) => {
    if (event.target.value.toString().length <= 476) {
      this.setState({
        description: event.target.value,
        LenghtDescriptionError: false,
      });
    }
    if (event.target.value.toString().length < 8) {
      this.setState({
        description: event.target.value,
        LenghtDescriptionError: true,
      })
    }
  };

  changeBorder = () => {
    this.setState({ currentActive: true },
      this.setState({ mapActive: false }),
      // () => console.log(this.state.currentActive),
      // () => console.log(this.state.mapActive)
    );
  }


  checkGPS = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.checkPosition, this.errorPosition
      );
    }
    //  else
    //   console.log("Geolocation is not supported by this browser.");

  };

  checkPosition = (position) => {
    this.setState({ positionenabled: true });
  };


  LoadImage = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    // console.log("addUpdateIndex " + addUpdateIndex);
    this.setState({ index: addUpdateIndex });
    this.setState({ images: imageList });
  };




  getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
    this.changeBorder();



  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
       console.log(error);
       // alert(error);
       this.openAlertGPS();
    }
  };

  showPosition = (position) => {
    // console.log(
    //   "Latitude: " +
    //   position.coords.latitude +
    //   "<br>" +
    //   "Longitude: " +
    //   position.coords.longitude
    // );

    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });
  };

  roundTo = (value, places) => {
    var power = Math.pow(10, places);
    return Math.round(value * power) / power;
  };


  updateLatLng = (lat, lng) => {
    // console.log("lat"+ lat);
    //  console.log("lng"+ lng);

    this.setState({ lat: lat });
    this.setState({ lng: lng });

  }

  /* *** INIZIO - SPERIMENTALE in uso ai i test della funzione per ricavare il frame dal video di upload *** */

  // getFrame = () => {

  //   var input = document.getElementById('upload');
  //   var img = document.getElementById('thumbnail');


  //   input.addEventListener('change', function (event) {

  //     var file = event.target.files[0];
  //     var url = URL.createObjectURL(file);
  //     var video = document.createElement('video');
  //     video.src = url;

  //     console.log(JSON.stringify(file));
  //     console.log("STAMPO FILENAME :" + file.name)
  //     console.log("STAMPO URL :" + url)
  //     console.log("STAMPO VIDEO :" + video)

  //     var snapshot = function () {

  //       var canvas = document.createElement('canvas');
  //       var ctx = canvas.getContext('2d');
  //       console.log("STAMPO CTX :" + ctx)

  //       ctx.drawImage(video, 0, 0, 300, 150);
  //       img.src = canvas.toDataURL('image/png');
  //       console.log("STAMPO IMG.SRC :" + img.src)
  //       video.removeEventListener('canplay', snapshot);

  //     };

  //     video.addEventListener('canplay', snapshot);

  //   });

  // }

  /* *** FINE - SPERIMENTALE in uso ai i test della funzione per ricavare il frame dal video di upload *** */

  dataform = (file) => {

    let app = window.localStorage.getItem("currentapp");
    let userId = window.localStorage.getItem("userid");
    let domain = window.localStorage.getItem("currentdomain");
    let uid=window.localStorage.getItem("uid");
    let form = {
      name: file.name,
      appname: app,
      userId: userId,
      domain: domain,
      degree: 2,
      creator: "",
      dash: "",
      dcRightsHolder: "",
      description: "{}",
      dest: "resource",
      hls: "",
      linkright: "https://creativecommons.org/publicdomain/zero/1.0/",
      locationlat: this.state.lat,
      locationlng: this.state.lng,
      mediaLanguage: "en",
      medialan: "en",
      mediares: "first",
      origin: "asset",
      right: "CC0",
      textcloudcontent: "",
      title: "{}",
      uid:uid
    }

    return form;
  }

  fileChoiced = (event) => {
    let fileList = this.state.cameraImage;
    let file = event.target.files[0];

    let fileValid = document.querySelector("#checkExtension");
    if (fileValid.files[0]) {

      if (/\.(jpe?g|png|gif|mov|mp4|webm|avi)$/i.test(fileValid.files[0].name) === true) {

        if (file !== undefined && file.size < 8388608) {

          this.setState({ file: file, filename: file.name });
          this.dataform(event.target.files[0])


          fileList.push(file);
          let arrBlob = this.state.blobCameraImage;
          let img = URL.createObjectURL(file);
          arrBlob.push(img)
          this.setState({ cameraImage: fileList, blobCameraImage: arrBlob });


          //settaggio cover al primo giro 
          if (fileList.length === 1) {
            // console.log("setto cover SOLO al primo giro")
            this.setState({ coverName: file.name })
          }


          countUpl++;

        } else
          // alert("File size is greater than 8 Mb")
          this.openAlertSize();


      }
      else
        // alert("estensione file non valida");
        this.openAlertExtension();
    }
  }



  async uploadEl(file) {
    let self = this;
    //  console.log(file)  
    // console.log(this.state.form);
    let token = window.localStorage.getItem("token");
    // console.log(token)
    //console.log("file"+JSON.stringify(this.state.file.name))
    let coverId = this.state.coverId;
    //chiamata al BE per upload risorsa multimediale 
    let sendForm = this.dataform(file);

    if (sendForm.name.length > 0 && file) {



      let serviceurl = process.env.REACT_APP_DOMAIN + services.resourcemanager_POST_AUTH;
      multipartformService(serviceurl, sendForm, token, file,
        function (err, result) {
          if (err) {
            // console.log("resource upload error" + err);
            return;
          }
          else if (result.status === 201) {
            // console.log("Upload ok");
            if (result.response) {
              // console.log("response " + JSON.stringify(result.response))
              console.log("ID RISORSA CARICATA  " + result.response._id)

              let arrId = self.state.arrayId;
              arrId.push(result.response._id)
              self.setState({ arrayId: arrId })
              //,()=>console.log(JSON.stringify(self.state.arrayId))


              //  console.log(result.response.fsname.split(" ").join(""));
              //  console.log(self.state.coverName.split(" ").join(""));


              //.split(" ").join("")
              if ((result.response.fsname.split(" ").join("")).includes(self.state.coverName.split(" ").join(""))) {
                // console.log("ok setto coverObj");

                self.setState({ coverId: result.response._id },
                  //  ()=> console.log(self.state.coverId)
                )
                coverId = result.response._id;

              }

              if (self.state.cameraImage.length === arrId.length) {
                self.createReport(coverId);
              }

            }


          }
        })

      /*if (!response.status === 201) {
        throw new Error('Request failed with status ' + response.status);
      }
      const data = await response.response._id;*/
      // console.log('Received data:', response);

    }

  }



  saveReport() {
    this.setState({ loader: true });
    /*prima di caricare gli elementi di cameraImage sposto la cover in posizione 0*/
    let indexCover = this.state.selectedValueCover;
    let orderedCameraImage = this.state.cameraImage;
    let n = 0;//nuovo indice
    let v = indexCover//vecchio indice
    // console.log(n, v);
    let x;//variabile ausiliaria
    x = orderedCameraImage[n];
    orderedCameraImage[n] = orderedCameraImage[v];
    orderedCameraImage[v] = x;

    //chiamata  x ogni elemento di cameraImage con cover spostata sempre in posizione 0
    for (var i = 0; i < orderedCameraImage.length; i++) {
      this.uploadEl(orderedCameraImage[i])


    }

    /*
    for (var i = 0; i < this.state.cameraImage.length; i++) {
 
      this.uploadEl(this.state.cameraImage[i])
 
    }*/


    this.closeDialog();
  };





  createReport = (coverId) => {
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    let userId = window.localStorage.getItem("userid");
    let resources = this.state.arrayId.toString();
    let tags = this.state.selectedHashtags.toString();

    //   console.log(this.state.coverId)
    //  console.log(coverId)

    // var self = this;
    let inputdata = {
      appname: app,
      domain: domain,
      name: this.state.title,
      description: this.state.description,
      userId: userId,
      resources: resources,
      tags: tags,
      locationlng: this.state.lng,
      locationlat: this.state.lat,
      gravity: this.state.gravity,
      //coverobj: this.state.coverId
      coverobj: coverId !== undefined ? coverId : this.state.coverId
    }

    console.log(JSON.stringify(inputdata));

    postService(process.env.REACT_APP_DOMAIN + services.createreport_POST, inputdata,
      "", "",
      function (err, result) {
        if (err) {
          // console.log("create report Error:  " + err);
        }
        else if (result.status === 201 && result.response) {
          // console.log("create report ok");
          // console.log(result.status);
          // console.log(result.response);

          // let url = "";
          //  url = "web-app/?app=" + app + "&domain=" + domain;
          //  window.open(encodeURI(url), "_self", "_system", "location=no");
          //  window.location.href = ('/' + url)

          // **** DA VERIFICARE ANCHE IN HOME****

          // self.props.setValueNav(2);
          // self.props.navigate('/web-app?app=" + app + "&domain=" + domain');  
          setTimeout(() => {
            let url = "";
            url = "web-app/?app=" + app + "&domain=" + domain;
            window.open(encodeURI(url), "_self", "_system", "location=no");
            window.location.href = ('/' + url);
          }, 10000);
        }
      }
    )
  }




  render() {

    const { t } = this.props;
      let lng = window.localStorage.getItem("i18nextLng");  


    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <div
          className="parentContainer"
          style={{
            paddingBottom: "40px",
            position: "relative",
            zIndex: "9",
            backgroundColor: "#ffb639",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={1}
          // style={{backgroundColor: "#ffb639"}}
          >
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <TextField
                style={{ width: "85%" }}
                id="title"
                label={t('main.lbl_title')}
                inputProps={{ min: 3, max: 30 }}
                error={this.state.LenghtTitleError}
                value={this.state.title}
                onChange={(event) => this.changeTitle(event)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={this.state.description}
                onChange={(event) => this.changeDescription(event)}
                style={{ width: "85%" }}
                inputProps={{ min: 8, max: 476 }}
                error={this.state.LenghtDescriptionError}
                id="description"
                label={t('main.lbl_description')}
                multiline
                rows={4}
              />
            </Grid>



            <Grid
              item
              xs={12}
              style={{
                // paddingLeft: "10%",
                // maxWidth: "98%",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  // width: "85vw",
                  alignItems: "center",
                }}
              >
                <Typography
                  className="mansalva-h6"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    // width: "85%"
                  }}
                >
                  {t('main.severity')}
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="Severity"
                    name="SeverityName"
                    value={this.state.gravity}
                    onChange={this.handleChangeSeverity}
                  >
                    <StyledFCL
                      value="0"
                      control={<Radio size="small" />}
                      label={t('main.severity_low')}
                    />
                    <StyledFCL
                      value="50"
                      control={<Radio size="small" />}
                      label={t('main.severity_medium')}
                    />
                    <StyledFCL
                      value="100"
                      control={<Radio size="small" />}
                      label={t('main.severity_high')}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "10%",
                paddingTop:"5%",
                paddingBottom:"5%",
                maxWidth: "93%",
              }}
            >
               <Typography
                  className="mansalva-h6"
                  color="primary"
                  variant="button"
                  style={{ left: "10%", position: "absolute" }}
                >
                  {'HASHTAGS'} :&nbsp;&nbsp;&nbsp;
                </Typography>
              
              <div className="hashBoxAdd"
               style={{ marginTop: "30px" }}
              >
                <ToggleButtonGroup
                  value={this.state.selectedHashtags}
                  onChange={(event, newValue) => {
                    this.selectHashtags(newValue);
                  }}
                >
                  {this.state.hashtags?.map((item) => (
                    <ToggleButton
                      // className="hashAdd"
                      // value={item.name["it"]}
                      value={item._id}
                      aria-label="bold"
                     // disabled={this.checkforBestPractice(item)}
                    >
                      <Typography className="mansalva-hasbuttons">
                        {item.name[lng]}
                      </Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </Grid>

            {/* *** INIZIO da usare solo per i test della funzione GETFRAME *** */}

            {/* <Grid item xs={12} style={{ width: "300px", height: "300px" }}>
            <input type="file" id="upload" onClick={() => this.getFrame()} />
            <img id="thumbnail"
              style={{
                width: "200px",
                height: "200px",
                backgroundColor: "green",
              }}
            />
          </Grid> */}

            {/* *** FINE da usare solo per i test della funzione GETFRAME *** */}



            <Grid item xs={12}>

              <Box component="span" className="box-upload">

                <div>
                  <span style={{ visibility: "hidden" }}>
                    <input
                      id="checkExtension"
                      className="hide-label"
                      ref={this.setRefFileinput}
                      onChange={this.fileChoiced}
                      type="file"
                      // accept=".jpg, .jpeg, .png, .mp4, .webm"
                      accept="image/*,video/*"
                      style={{ visibility: "hidden" }}
                      hidden
                    />
                  </span>
                  <Button
                    // id="limit"
                    variant="contained"
                    color="primary"
                    aria-label="upload resource"
                    component="span"
                    size="small"
                    startIcon={<PhotoCameraIcon />}
                    onClick={this.refFileinputClick}
                  >
                    {t('main.btn_uplmultimedia')}
                  </Button>
                </div>
              </Box>

              {/* <Grid item xs={12}>
               <label> {this.state.filename}</label><br />
              {this.state.file ?
                this.state.file.type === "video/mp4" || this.state.file.type === "video/webm" ?
                  <video controls width="150px" height="auto" src={URL.createObjectURL(this.state.file)} /> :
                  <Typography style={{ display: "none" }}>no preview</Typography> :
                null
              }
            </Grid> */}
            </Grid>
            {this.state.loader ?
              <div className="progress">
                <CircularProgress
                  size={140}
                  color="primary"
                  disableShrink
                  style={{ zIndex: 999, position: "absolute", right: "33vw" }}
                />
              </div>
              : <></>}
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "10%",
                // marginRight: "10%"
                maxWidth: "93%",
              }}
            >
              <div className="hashBoxAdd">
                <div className="scroll-added">


                  {this.state.cameraImage.length === 0 ?
                    (<Card sx={{ width: 280 }}>
                      <CardMedia
                        sx={{ height: 220, backgroundSize: "contain" }}
                        image="/images/Creates.png"
                        title="greenscent"
                        style={{ backgroundColor: "#ffb63957" }}
                      />
                      <CardActions>
                        <FormControlLabel
                          // value="defaultCover"
                          control={
                            <Radio
                              checked={false}
                              disabled
                              size="small"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "defaultCover" }}
                            />
                          }
                          label={
                            <Typography
                              variant="caption"
                              display="block"
                              color="#d2d2d2"
                            >
                              Cover
                            </Typography>
                          }
                        />
                        <FormGroup>
                          {/*  <FormControlLabel
                          control={<Switch size="small" disabled />}
                          label={
                            <Typography
                              variant="caption"
                              display="block"
                              color="#d2d2d2"
                            >
                              360
                            </Typography>
                          }
                        />*/}
                        </FormGroup>
                        <IconButton style={{ left: "0px", cursor: "default" }}>
                          <DeleteIcon
                            size="small"
                            style={{ color: "#d2d2d2" }}
                            disabled
                          />
                        </IconButton>
                      </CardActions>
                    </Card>)
                    :
                    (this.state.cameraImage.map((image, index) => (
                      <div key={index} item style={{ marginLeft: "10px" }}>
                        <Card sx={{ width: 280 }}>
                          {image && image.type.includes("image") ?
                            <CardMedia
                              sx={{ height: 220, backgroundSize: "cover" }}
                              image={image && image.type.includes("image") ? this.state.blobCameraImage[index] : ("/images/Create.png")}
                              title="greenscent"
                            />
                            : <video controls height="200px" width="280px" src={URL.createObjectURL(image)} />}
                          <CardActions>
                            <FormControlLabel
                              // value="defaultCover"
                              control={
                                <Radio
                                  checked={
                                    this.state.selectedValueCover === index
                                      ? true
                                      : false
                                  }
                                  onChange={() => this.handleChangeCover(index)}
                                  size="small"
                                  value={index}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "defaultCover" }}
                                />
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  display="block"
                                  color="primary"
                                >
                                  {t('main.radiobtn_cover')}
                                </Typography>
                              }
                            />
                            <FormGroup>
                              {/* <FormControlLabel
                              control={<Switch size="small" />}
                              label={
                                <Typography
                                  variant="caption"
                                  display="block"
                                  color="primary"
                                >
                                  360
                                </Typography>
                              }
                            />*/}
                            </FormGroup>
                            <IconButton color="primary" style={{ left: "0px" }}
                            >
                              <DeleteIcon
                                onClick={() => this.deleteUploadFile(index)}
                                size="small" />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </div>
                    )))
                  }

                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginLeft: "10%",
                marginRight: "10%",
                marginTop: "30px",
              }}
            >
              {this.state.lat === 0 ? (
                <Typography
                  className="mansalva-h6"
                  color="primary"
                  variant="button"
                  style={{ left: "10%", position: "absolute" }}
                >
                  {t('main.lbl_GPSLocation')} :&nbsp;&nbsp;&nbsp;
                </Typography>
              ) : (
                <Typography
                  className="mansalva-h6"
                  color="primary"
                  variant="button"
                  style={{ left: "10%", position: "absolute" }}
                >
                  {t('main.lbl_GPSLocation')} :&nbsp;&nbsp;&nbsp;LAT.&nbsp;{this.roundTo(this.state.lat, 4)},&nbsp;LNG.&nbsp;{" "}
                  {this.roundTo(this.state.lng, 4)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginTop: "30px" }}>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={this.getLocationUser}
                  disabled={this.state.positionenabled === true ? false : true}
                  sx={{
                    width: "130px",
                    left: "10%",
                    color: this.state.currentActive ? "#ffb639" : "",
                    border: this.state.currentActive ? "2px solid #ffb639" : "",
                  }}
                  variant="contained"
                  endIcon={<PlaceIcon />}
                >
                  {t('main.btn_setcurrent')}
                </Button>


                <Button
                 // disabled={this.state.positionenabled === true ? false : true}
                  onClick={() => {
                    this.openMapAdd();
                  }}

                  sx={{
                    width: "130px",
                    right: "10%",
                    color: this.state.mapActive ? "#ffb639" : "",
                    border: this.state.mapActive ? "2px solid #ffb639" : "",
                  }}
                  variant="contained"
                  endIcon={<EditLocationIcon />}
                >
                  {t('main.btn_setonmap')}
                </Button>

              </Box>

              <DialogMapAdd open={this.state.openDialogMap}
                closeSave={() => this.closeSaveMapAdd()}
                callback={this.updateLatLng}
                closeCancel={() => this.closeCancelMapAdd()}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: "30px",
              padding: "0px 10% 0px 10%",
            }}
          >
            <Button
              style={{ width: "100%" }}
              variant="contained"
              disabled={
                this.state.title === "" ||
                this.state.description === "" ||
                this.state.lat === 0 ||
                this.state.lng === 0 ||
                this.state.selectedHashtags.length === 0 ||
                this.state.LenghtTitleError === true ||
                this.state.LenghtDescriptionError === true
              }
              onClick={() => {
                this.openDialogYoN();
              }}
            >
              {t('main.btn_send')}
            </Button>
            <DialogYesNo
              open={this.state.openDialog}
              close={() => this.closeDialog()}
              callback={() => this.saveReport()}
            />
          </Grid>

          <Snackbar
            style={{ marginTop: "60px" }}
            open={this.state.showAlertExtension}
            autoHideDuration={8000}
            onClose={this.closeAlertExtension}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlertExtension}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
              }}
            >
              {t('main.alert_extension')}
            </Alert>
          </Snackbar>


          <Snackbar
            style={{ marginTop: "60px" }}
            open={this.state.showAlertSize}
            autoHideDuration={8000}
            onClose={this.closeAlertSize}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlertSize}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
              }}
            >
              {t('main.alert_size')}
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginTop: "60px" }}
            open={this.state.showAlertCounter}
            autoHideDuration={8000}
            onClose={this.closeAlertCounter}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlertCounter}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
              }}
            >
              {t('main.alert_maxupl')}
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginTop: "60px" }}
            open={this.state.showAlertGPS}
            autoHideDuration={8000}
            onClose={this.closeAlertGPS}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeAlertGPS}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

              style={{
                marginBottom: "2px",
                maxWidth: "80vw",
              }}
            >
              {"Your geolocation is not enabled"}
            </Alert>
          </Snackbar>


        </div>
      </Suspense>
    );
  }
}


export default (withTranslation())(withRouter(Add));
