import React, { Component } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import ClearIcon from '@mui/icons-material/Clear';
// import SendIcon from '@mui/icons-material/Send';
import services from '../../services/services.json';
import {
  // postService,
  getService
} from "../../services/services";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogForgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailerror: true,
      selectDomain: "",
      domain: [],

    };

  }
  componentDidMount() {
    this.getDomains();
  }

  getDomains = () => {

    // let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");

    // console.log(this.props.configuration.app)
    var self = this;
    getService(process.env.REACT_APP_DOMAIN + services.domains_GET.replace("{APPNAME}", app),
      "",
      function (err, result) {
        if (err) {
          // console.log("domains_GET Error");
        } else if (result.status === 200) {
          // console.log("domains OK");
          // console.log(result.response);
          self.setState({ domain: result.response }
            // , () => console.log(JSON.stringify(self.state.domain.length))
          );
        }
      }
    );
  }

  onclose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailerror: ((event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null || event.target.value.length === 0) ? true : false)
    });
  }

  handleChangeDomain = (e) => {
    this.setState({
      selectDomain: e.target.value
    },
      // () => console.log(this.state.selectDomain)
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle >
            {/* <LockOpenIcon
            color='primary'
            aria-label="recovery password"
            style={{

              backgroundColor: "#ffb639",
              borderRadius: "50px",
              fontSize: "40px",
              padding: "5px",
            }}
          /> */}
            <span style={{
              color: "#004c43",
              fontWeight: 400,
              fontSize: "1.4rem",
              fontFamily: 'Mansalva',
            }}>
              Forgot Password
            </span>
          </DialogTitle>
          <DialogContent>

            <div>
              <div style={{ paddingBottom: 20 }}>
                <TextField
                  variant="outlined"
                  label="email"
                  required
                  type="email"
                  id="email"
                  fullWidth
                  error={this.state.emailerror}
                  onChange={this.handleChangeEmail}
                />
              </div>
              <div style={{ paddingBottom: 40 }}>
                <Select
                  sx={{ mt: 2 }}
                  color="primary"
                  required
                  id="id"
                  displayEmpty
                  fullWidth
                  value={this.state.selectDomain}
                  onChange={this.handleChangeDomain}
                >
                  <MenuItem value="" disabled>
                    <em>Select domain</em>
                  </MenuItem>
                  {this.state.domain.map((domain, i) =>
                    <MenuItem key={i}
                      className="MuiInputBase-input-custom"
                      value={domain._id}>
                      {domain.shortname.toUpperCase()}
                    </MenuItem>
                  )}

                </Select>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
            // startIcon={<ClearIcon />}
            >
              <Typography variant="button">{t('main.btn_cancel')}</Typography>{t('main.btn_yes')}
            </Button>
            {this.state.emailerror === false && this.state.selectDomain !== "" ?
              <Button
                variant="contained"
                size="small"
                onClick={this.callback}
                color="primary"
              // startIcon={<SendIcon />}
              >
                <Typography >{t('main.btn_send')}</Typography>
              </Button>
              : <></>}
          </DialogActions>
        </Dialog>
      </Suspense>
    )
  }
};

export default withTranslation()(DialogForgot);