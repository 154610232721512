import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import "./Welcome.css";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
//import {getDomains} from "../utils/utility";
import services from "../services/services.json";
import { getService } from "../services/services";
import { connect } from "react-redux";

import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
  ...state,
});



class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      // positionenabled: false,
      openSnackPosition: false,
      showAlert: true, //mostra sempre l'avviso
      //domains:[],
      domain: [],
      language:"",
    };
  }

  componentDidMount() {
    // this.getLocationUser();
    // this.setState({domains:getDomains()});
    this.getDomain();
    let initLan=(window.localStorage.getItem('i18nextLng'))
    window.localStorage.setItem("language", initLan);
  }

  getDomain = () => {
    // console.log(this.props.configuration.app)
    var self = this;
    getService(process.env.REACT_APP_DOMAIN + services.domains_GET.replace("{APPNAME}", self.props.configuration.app),
      "",
      function (err, result) {
        if (err) {
          // console.log("domains_GET Error");
        } else if (result.status === 200) {
          // console.log("domains OK");
          // console.log(result.response);
          self.setState({ domain: result.response }, 
            
          //  () => console.log(JSON.stringify(self.state.domain.length))
            );
        }
      }
    );

  }




  getUrl = (item) => {
    // console.log(item.shortname);
    let domainshortname = item.shortname;
    window.localStorage.setItem("domainshortname", domainshortname);

    let url = "";

    url = "web-app/?app=" + this.props.configuration.app + "&domain=" + item._id;

    window.open(encodeURI(url), "_self", "_system", "location=no");
    return url;

  };

  checkDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Take the user to a different screen here.
      // console.log(navigator.userAgent)
      return "Mobile";
    } else return "Desktop";
  };

  /*getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition,
        // this.setState({ showAlert: true })
      );
    } else {
      console.log("Geolocation is not supported by this browser."); //all'inizio e se non supportato bisogna posizionarsi a Roma
    }
  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      console.log(error);
      // this.setState({ showAlert: true });
    }
  };

  showPosition = (position) => {
    console.log(
      "Latitude: " +
        position.coords.latitude +
        "<br>" +
        "Longitude: " +
        position.coords.longitude
    );
    this.setState({ positionenabled: true });
  };*/

  closeAlert = () => {
    this.setState({ showAlert: false });
  };







  getDomainsForUser = (item) => {
    let flag = false;

    let domain = window.localStorage.getItem("currentdomain");
    let userLogin = window.localStorage.getItem("userlogged");
    //  console.log(domain, userLogin);

    if (domain !== "" && userLogin === "true") {
      if (domain === item._id) {
        flag = false;
        this.getOrderedDomains(item._id);
      } else {
        flag = true;
      }
    }

    return flag;
  }



  getOrderedDomains = (id) => {
    let self = this;
    let index = self.state.domain.findIndex(item => item._id === id);
    // console.log(index);

    let n = 0;//nuovo indice
    let v = index//vecchio indice

    // console.log(n, v);

    let x;//variabile ausiliaria
    x = self.state.domain[n];
    self.state.domain[n] = self.state.domain[v];
    self.state.domain[v] = x;
    // console.log(x);
  }

  render() {

    //  let domain = window.localStorage.getItem("currentdomain");
    // let userLogin = window.localStorage.getItem("userlogged");
    //  console.log(domain, userLogin);
    const { t } = this.props;


    return (
      <div>
         <Suspense fallback={(<div>Loading</div>)}>
        <Box
          sx={
            {
              // flexGrow: 1
            }
          }
        >
          <Grid
            container
          // rowSpacing={1}
          // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} style={{ backgroundColor: "#ffb639" }}>
              <img
                alt="youths"
                className="responsiveWel"
                src="/images/Image 4_youths.png"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                alt="logo"
                className="responsiveImg"
                src="/images/logo.png"
              />
            </Grid>

            <Grid item xs={12}>
              {this.checkDevice() === "Mobile" ? (
                <div>
                  <Box style={{ paddingBottom: 48 }}>
                    <Tabs
                      value={this.state.value}
                      onChange={(event, newValue) => {
                        this.setState({ value: newValue });
                      }}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable tabs"
                    >
                      {this.state.domain !== [] && this.state.domain.map((item) => (
                        <Tab
                          label=""
                          icon={
                            <>
                              <IconButton
                                color="primary"
                                disabled={this.getDomainsForUser(item)}
                                // disabled= {(domain!==""&&userLogin==="true")? (domain===item._id ? false : true) : false}
                                aria-label="dominio"
                                disableRipple="true"
                                onClick={() => { this.getUrl(item) }}
                              >
                                <figure>
                                  <img
                                    className="icone-domini"

                                    src="../images/icone-domini/generic.png"

                                    alt=""
                                  />

                                  <Typography variant="h6">{item.shortname.toUpperCase()}</Typography>

                                </figure>
                              </IconButton>
                            </>
                          }
                        ></Tab>
                      ))}
                    </Tabs>
                  </Box>
                </div>
              ) : (
                <Box style={{ paddingBottom: 78 }}>
                  <img alt="logo" src="/images/pwa.jpg" />

                  <Grid item xs={12}>
                    <Typography color="primary" variant="button">
                      NAVIGATION ENABLED IN APP
                      <br />
                      ask for more information to get started
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Grid>

            <Snackbar
              open={this.state.showAlert}
              autoHideDuration={8000}
              onClose={this.closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={this.closeAlert}
                sx={{
                  mt: "45vh",
                  width: "100%",
                  // height:150,
                  backgroundColor: "#ffba20",
                  color: "#004c43",
                }}
                elevation={8}
                variant="filled"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5, color: "black" }}
                    onClick={this.closeAlert}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                <Typography style={{ textAlign: "left" }}>
                {t('main.welcome_msg')}
                </Typography>

              </Alert>
            </Snackbar>
            <AppBar
              position="fixed"
              style={{ top: "auto", bottom: 0, textAlign: "justify" }}
            >
              <Box sx={{ display: "flex" }}>
                <img
                  style={{ width: 110 }}
                  src="/images/flag_of_europe.png"
                  alt=""
                  className="icon-master"
                />
                {/* <Link
                  target="_blank"
                  href="https://ec.europa.eu/info/research-and-innovation/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-2020_en"
                  rel="noreferrer"
                > */}
                <Typography variant="caption" style={{ color: "#fff" }}>
                  This project has received funding from the European Union's
                  Horizon 2020 research and innovation programme under grant
                  agreement No 101036480. &copy; Greenscent. All right
                  reserved
                </Typography>
                {/* </Link> */}
              </Box>
            </AppBar>
          </Grid>
        </Box>
        </Suspense>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(Welcome));


