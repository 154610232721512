import React, { Component } from 'react';

import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ImageGallery from 'react-image-gallery';
// import HelpIcon from '@mui/icons-material/Help';
import services from "../../services/services.json";
import {
  // getService
} from "../../services/services";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const renderVideo = (item) => {
  return (
    <div className="video-wrapper">
      <iframe
        width="100%"
        height="480px"
        src={item.embedUrl}
        title="ex"
      />
    </div>
  );
};



class DialogImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "titolo dialog",
      info: "messaggio dialog",
      // open:this.props.open
      item: this.props.itemDetail,
      images: []
    };

  }

  componentDidMount() {
    // this.setState({ hashtags: getHashtags() });
    this.urlResource();
    // console.log(this.props.itemDetail);
  }



  onclose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;

    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }



  urlResource = () => {
    // console.log(this.state.item.resources.length);
    let images = this.state.images;
    let url = "";

    for (var i = 0; i < this.state.item.resources.length; i++) {
      let userid = window.localStorage.getItem("userid");
      let domain = window.localStorage.getItem("currentdomain");
      let app = window.localStorage.getItem("currentapp");
      url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;
      url = url.replace("{APPNAME}", app);
      url = url.replace("{DOMAIN}", domain);
      url = url.replace("{USERID}", userid);

      if (this.state.item.resources[i]?.type === "image") {
        url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + this.state.item.resources[i]?._id; //eslint-disable-line


        var objImg = {
          original: url,
          thumbnail: url,
        }

        images.push(objImg);

      } else {
        let vidUrl = url + "&cover=true&medialan=en&mediares=first&" + "id=" + this.state.item.resources[i]?._id; //eslint-disable-line
        let embedUrl = url + "&cover=false&medialan=en&mediares=first&" + "id=" + this.state.item.resources[i]?._id; //eslint-disable-line
        //  console.log("URL   "+vidUrl)
        //  console.log("embedURL   "+embedUrl)

        var objVid = {
          embedUrl: embedUrl,
          original: '',
          thumbnail: vidUrl,
          renderItem: renderVideo.bind(this)
        }

        images.push(objVid);

      }
      // console.log(JSON.stringify(images));
      // console.log(url);
    }
    this.setState({ images: images },
      // () => console.log(JSON.stringify(this.state.images))
    );
  }




  render() {
    const { t } = this.props;
    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogContent>
            <ImageGallery
              items={this.state.images}
              onSlide={this.handleSlideChange}
              showVideo={this.showVideo}
              useBrowserFullscreen={false}
              showNav={false}
              showPlayButton={false}
              stopPropagation={true}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
            >
              <Typography>{t('main.btn_close')}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Suspense>
    )
  }
};

export default withTranslation()(DialogImages);