import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'

import configureStore from "./redux/store";
import { ParallaxProvider } from 'react-scroll-parallax';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ParallaxProvider>
    <Provider store={configureStore()}>
     <App />
    </Provider>
  </ParallaxProvider>
);
//getConfiguration();

// function getConfiguration() {
//   let params = new URLSearchParams(window.location.search);
//   let appname = params.get("app");
//   let domainname = params.get("domain");

//   console.log("index:" +appname, domainname);

//   if (!appname) {
//     appname = window.localStorage["currentapp"];
//   }

//   if (!domainname) {
//     appname = window.localStorage["currentdomain"];
//   }

//   if (appname) {
//     window.localStorage["currentapp"] = appname;
//   }

//   if (domainname) {
//     window.localStorage["currentdomain"] = domainname;
//   }
// }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
