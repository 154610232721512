import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Header from "./Header";
//import "./Welcome.css";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import Button from "@mui/material/Button";
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import services from "../services/services.json";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import {
  postService,
  // getService
} from "../services/services";
import DialogRegistrationOk from "./Dialog/DialogRegistrationOk";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
// const mapStateToProps = (state) => ({
//   ...state,
// });


const theme = createTheme({

  palette: {
    primary: {
      main: "#025148",
    },
    secondary: {
      main: "#ff7646",
    },
  }
});

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      openDialog: false,
      password_confirm: '',
      instance: "ideha",
      role: "user",
      username: '',
      // selectDomain: "",
      domain: "",
      emailerror: false,
      passworderror: false,
      nameerror: false,
      password_confirmerror: false,
      showPassword: false,
      showRepeatPassword: false,
      openDisclaimer: false,
      okDisclaimer: false,
      showAlert: false,
      // showSuccess: false,
      loader: false,
    };
  }


  componentDidMount() {
    this.getDomains();

  }

  // handleChangeDomain = (e) => {
  //   this.setState({
  //     selectDomain: e.target.value
  //   }
  //     // , () => console.log(this.state.selectDomain)
  //   );
  // }

  openDialogSuccess = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });


    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");

    let url = "";
    url = "web-app/?app=" + app + "&domain=" + domain;
    window.open(encodeURI(url), "_self", "_system", "location=no");
    window.location.href = ('/' + url)

  };

  handleChangeNickname = (event) => {
    this.setState({ name: event.target.value, nameerror: (event.target.value.length > 2 ? false : true) });
  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value, emailerror: (event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null ? true : false) });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value, passworderror: (event.target.value.length > 6 ? false : true), password_confirmerror: ((event.target.value !== this.state.password_confirm && this.state.password_confirm.length > 0) ? true : false) });

  }
  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };

  // closeSuccess = () => {
  //   this.setState({ showSuccess: false });
  // };

  // openSuccess = () => {
  //   this.setState({ showSuccess: true });
  // };

  handleChangeConfirmPassword = (event) => {
    this.setState({
      password_confirmerror: (this.state.password === event.target.value) ? false : true, password_confirm: event.target.value
    })
  }

  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  showRepeatPassword = () => {
    this.setState({ showRepeatPassword: !this.state.showRepeatPassword });
  }

  getDomains = () => {
    let domainshortname = window.localStorage.getItem("domainshortname");

    this.setState({ domain: domainshortname })
  }


  // getDomains = () => {

  //   // let domain = window.localStorage.getItem("currentdomain");
  //   let app = window.localStorage.getItem("currentapp");

  //   // console.log(this.props.configuration.app)
  //   var self = this;
  //   getService(process.env.REACT_APP_DOMAIN + services.domains_GET.replace("{APPNAME}", app),
  //     "",
  //     function (err, result) {
  //       if (err) {
  //         console.log("domains_GET Error");
  //       } else if (result.status === 200) {
  //         // console.log("domains OK");
  //         // console.log(result.response);
  //         self.setState({ domain: result.response }
  //           // , () => console.log(JSON.stringify(self.state.domain.length))
  //         );
  //       }
  //     }
  //   );
  // }



  registrationRequest = () => {
    this.setState({ loader: true });
    var self = this;
    let app = window.localStorage.getItem("currentapp");
    let domain = window.localStorage.getItem("currentdomain");

    let params = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      domain: domain,
      appname: app
    }
    postService(process.env.REACT_APP_DOMAIN + services.registration_POST, params,
      "", "",
      function (err, result) {

        if (err) {
          // console.log("registration Error:  " + err);


          self.setState({ loader: false });
          self.openAlert();
        }


        else if (result.status === 200) {
          // console.log("login ok");
          // console.log(JSON.stringify(result.response));


          self.setState({ loader: false });
          // self.openSuccess();
          self.openDialogSuccess();


        }

      });

  }

  render() {

    const { t } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Suspense fallback={(<div>Loading</div>)}>
          <div className="overlay">
            <Header />
            <Box sx={{ flexGrow: 1, mt: "52px", p: 2 }}>
              <Typography>
                <span
                  style={{
                    fontFamily: "Mansalva",
                    fontSize: "30px",
                    color: "#004c43",
                  }}
                >
               {t('main.title_REGISTER')}
                </span>
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  {/* <Grid item xs> */}
                  <br />
                  <div className="paper">
                    <div className="form-group">
                      <TextField
                        inputProps={{
                          // autoComplete: 'off',
                          maxLength: 25,
                          minLengh: 2,
                        }}
                        variant="outlined"
                        label={t('main.lbl_name')}
                        error={this.state.nameerror}
                        fullWidth
                        onChange={this.handleChangeNickname}
                        name={t('main.lbl_name')}
                        type="text"
                        value={this.state.name}
                        labelWidth={120}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        // inputProps={{ autoComplete: 'off' }}
                        sx={{ mt: 2 }}
                        variant="outlined"
                        label={t('main.lbl_emailaddr')}
                        error={this.state.emailerror}
                        fullWidth
                        onChange={this.handleChangeEmail}
                        name={t('main.lbl_emailaddr')}
                        id="email"
                        type="text"
                        value={this.state.email}
                      />
                    </div>
                    <div className="form-group">
                      {/* <label className="visuallyhidden" for="password">PASSWORD</label> */}
                      <OutlinedInput
                        inputProps={{
                          maxLength: 16,
                        }}
                        sx={{ mt: 2 }}
                        aria-label="Password"
                        autocomplete="new-password"
                        placeholder={t('main.lbl_password')}
                        error={this.state.passworderror}
                        fullWidth
                        onChange={this.handleChangePassword}
                        name="Password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              color="inherit"
                              fontSize="large"
                              onClick={this.showPassword}
                              edge="start"
                              aria-label="hide show password"
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                    <div className="form-group">
                      {/* <label for="confirm">{jsonlang.confirmpassword.toUpperCase()}</label> */}
                      {/* <label className="visuallyhidden" for="confirm password">confirm password</label> */}
                      <OutlinedInput
                        inputProps={{
                          maxLength: 16,
                        }}
                        sx={{ mt: 2 }}
                        aria-label="Confirm password"
                        autocomplete="New-password"
                        placeholder={t('main.lbl_confpassword')}
                        error={this.state.password_confirmerror}
                        fullWidth
                        onChange={this.handleChangeConfirmPassword}
                        name="Password_confirm"
                        type={this.state.showRepeatPassword ? 'text' : 'password'}
                        value={this.state.password_confirm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              color="inherit"
                              fontSize="large"
                              onClick={this.showRepeatPassword}
                              edge="start"
                            >
                              {this.state.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        //inputProps={{ autoComplete: 'off' }}
                        disabled
                        id="outlined-required"
                        label={t('main.lbl_domain')}
                        fullWidth
                        name="domain"
                        type="text"
                        value={this.state.domain.toUpperCase()}
                        sx={{ mt: 2 }}
                      />
                      <br />

                    </div>

                    <div className="form-group">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            disabled={(
                              this.state.nameerror === false &&
                                this.state.emailerror === false &&
                                this.state.passworderror === false &&
                                this.state.password_confirmerror === false &&
                                this.state.password.length > 6 &&
                                // this.state.selectDomain !== "" &&
                                this.state.password_confirm.length > 6
                                ? false : true)}
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={this.registrationRequest}
                          >
                            {this.state.loader ?
                              <CircularProgress
                                size={36}
                                style={{ color: "#ffb639" }}
                                disableShrink
                              />
                              : "SEND"}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <DialogRegistrationOk
              open={this.state.openDialog}
              close={() => this.closeDialog()}
            />

            <Snackbar
              open={this.state.showAlert}
              // autoHideDuration={4000}
              onClose={this.closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this.closeAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }

                style={{
                  marginBottom: "2px",
                  maxWidth: "80vw",
                }}
              >
                <Typography variant="button" display="block">
                  <b>{t('main.dlgmsg_registfailed')}</b><br />
                  {t('main.dlgmsg_loginmes')}
                </Typography>
              </Alert>
            </Snackbar>

            {/* <Snackbar
            open={this.state.showSuccess}
            // autoHideDuration={4000}
            onClose={this.closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.closeSuccess}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
             
                            style={{
                                marginBottom: "2px",
                                maxWidth: "80vw",
                            }}
            >
              <Typography variant="button" display="block">
                <b>REGISTRATION COMPLETED!</b><br />
                We'll send you an email to confirm your account.
              </Typography>
            </Alert>
          </Snackbar> */}

          </div>
        </Suspense>
      </ThemeProvider>
    );
  }
}

export default (withTranslation())(Registration);
