import React, { Component } from "react";
import DialogReportDetail from "./Dialog/DialogReportDetail";
import "./Saved.css";
import services from "../services/services.json";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazy-load';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from "@mui/material/Typography";


class Saved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsSaved: this.props.items,
      open: false,
      setOpen: false,
      scrollY: false,
      openDialogDetail: false,
      itemDetail: [],
      positionenabled: false,
      lat: 0,
      lng: 0,
      kilometres: 0,
      itemsbyUser: [],
      valueNav: this.props.valueNav,
      cover: "",
      showAlert: false,
      badge: 0
    };

  }

  componentDidMount() {

    // console.log("itemsSaved:" + JSON.stringify(this.state.itemsSaved.name));
    this.getLocationUser();
    this.filterItemsByUser();
    //console.log("valueNavSAVED"+this.state.valueNav)
  }


  filterItemsByUser = () => {

    let userLogin = window.localStorage.getItem("userlogged");
    let userid = window.localStorage.getItem("userid");
    //  console.log(userLogin, userid);

    let array_filtered = [];


    if (userLogin === "true") {
      // console.log("----------user loggato-----------" + userid);

      for (let i = 0; i < this.state.itemsSaved.length; i++) {
        // console.log(JSON.stringify(this.state.itemsSaved[i].owner));
        if (userid === this.state.itemsSaved[i].owner) {
          //elimina i doppioni
          // if (!array_filtered.includes(this.state.itemsSaved[i]))
          array_filtered.push(this.state.itemsSaved[i]);

        }
      }
      // }
      // console.log(JSON.stringify("array_filtered: " + array_filtered.length));
      this.setState({ itemsbyUser: array_filtered })
    }
    else this.setState({ itemsbyUser: this.state.itemsSaved })
  }

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };

  getLocationUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.errorPosition
      );
    } else {
      // console.log("Geolocation is not supported by this browser."); 
    }
  };

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  };

  showPosition = (position) => {

    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });
    this.setState({ positionenabled: true });
  };

  getDistance = (item) => {
    let R = 6371e3; // metres
    let φ1 = (this.state.lat * Math.PI) / 180; // φ, λ in radians
    let φ2 = (item.location.coordinates[1] * Math.PI) / 180;
    let Δφ =
      ((item.location.coordinates[1] - this.state.lat) *
        Math.PI) /
      180;
    let Δλ =
      ((item.location.coordinates[0] - this.state.lng) *
        Math.PI) /
      180;

    let a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = Math.round(d) / 1000;
    // return d.toFixed(0)/1000;
    return KM;
  };

  openDialogDetail = (item) => {

    this.getkilometresItemDetail(item);
    this.getCoverItemDetail(item);
    this.getResourcesNumber(item);
    this.getAvatarItemDetail(item);
    this.getNicknameItemDetail(item);

    this.setState({ itemDetail: item }, () => this.open());

  }


  getResourcesNumber = (item) => {

    let badge = item.resources.length
    //console.log(badge)
    this.setState({ badge: badge })
  }

  getkilometresItemDetail = (item) => {

    let kilometres = this.getDistance(item).toFixed(2);
    this.setState({ kilometres: kilometres })

  }
  getAvatarItemDetail = (item) => {
    let avatar = item.ownerdetails[0].avatar;
    this.setState({ avatar: avatar })
  }
  getNicknameItemDetail = (item) => {
    let nickname = item.ownerdetails[0].name;
    this.setState({ nickname: nickname })
  }
  getCoverItemDetail = (item) => {
    let cover = this.urlResource(item);
    this.setState({ cover: cover })
  }

  urlResource = (item) => {
    let url = "";
    let userid = window.localStorage.getItem("userid");
    let domain = window.localStorage.getItem("currentdomain");
    let app = window.localStorage.getItem("currentapp");
    url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;

    url = url.replace("{APPNAME}", app);
    url = url.replace("{DOMAIN}", domain);
    url = url.replace("{USERID}", userid);

    // console.log(item)

    if (item.coverobj?.type === "image") {

      //sostituire  item.resources[0]?._id  con item.coverobj?._id
      url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + item.coverobj?._id; // eslint-disable-line

    } else {
      url = url + "&cover=true&medialan=en&mediares=first&" + "id=" + item.coverobj?._id; //eslint-disable-line
    }

    return url;

  }

  getUTCData = (data) => {
    // console.log(data);
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short'
    }).format(new Date(data));
  }


  open = () => {
    this.setState({ openDialogDetail: true });
  }


  closeDialogDetail = () => {
    this.setState({ openDialogDetail: false });
  };


  render() {
    // let avatar = window.localStorage.getItem("avatar");
    // let username = window.localStorage.getItem("name");
    const { t } = this.props;

    return (
      <Suspense fallback={(<div>Loading</div>)}>

        <div>

          <>

            {this.state.itemsbyUser.length === 0 ?
              <>
                <Typography
                  sx={{
                    p: 3,
                  }}>
                  <span
                    style={{
                      // fontFamily: "Mansalva",
                      fontSize: "36px",
                      color: "#004c43",
                    }}
                  >
                    {t('main.alert_itemsnotfound')}
                  </span>
                </Typography>
              </>

              :
              <>

                {this.state.itemsbyUser.length > 0 && this.state.itemsbyUser.map((item, index) => (

                  <div className="parentContainer" style={{
                    bottom: "0px",
                    position: "relative",
                    zIndex: "9"
                  }}>
                    <LazyLoad height={"26vh"} threshold={0.75}>
                      <img style={{
                        height: "25vh",
                        width: "100vw",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                        alt=""
                        // src={item.uriCoverExternalRes}
                        src={this.urlResource(item)}
                        onClick={() => { this.openDialogDetail(item) }}
                      />
                    </LazyLoad>
                    <div className="top-right-saved">


                      {/* {this.props.avatar !== undefined && this.props.avatar !== "" && this.props.avatar !== null?

                      <img alt="" className="img-user-saved" src={avatar} />
                      :
                      <AccountCircleIcon style={{ fontSize: "50" }} />
                    } */}

                      {item.ownerdetails[0].avatar !== undefined ?

                        <img alt="" className="img-user-content"
                          src={item.ownerdetails[0].avatar} />
                        :
                        <AccountCircleIcon style={{ fontSize: "50" }} />
                      }


                    </div>

                    <div className="bottom-left-content">
                      <div className="container-detail">
                        <div className="contentLeft">
                          <span className="cut">
                            <IconButton
                              style={{ padding: "1px" }}
                              onClick={() => {
                                this.openAlert();
                              }}
                            >
                              {item.status === "APPROVED" ?
                                <BookmarkIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                :
                                item.status === "INSERTED" ?
                                  <BookmarkAddIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                  :
                                  item.status === "SOLVED" ?
                                    <BookmarkAddedIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                    :
                                    item.status === "REJECTED" ?
                                      <BookmarkRemoveIcon style={{ color: "#ffb639", fontSize: "28px" }} />
                                      : <></>}
                            </IconButton>
                            {item.name}
                          </span>
                          <span
                            style={{
                              fontSize: "13px"
                            }}>{this.getUTCData(item.modifiedAt)}
                          </span>
                        </div>
                        <div className="contentRight">
                          <img
                            src={this.state.positionenabled === true ? "/images/itinerary.png" : "/images/itinerary-disabled-red.png"}
                            alt="background"
                            style={{
                              width: "32px",
                              marginLeft: "16px",
                              position: "relative",
                              top: "5px"
                            }}
                          ></img>
                          <span
                            style={{
                              fontSize: "13px",
                              visibility:
                                this.state.positionenabled === true
                                  ? "visible"
                                  : "hidden",
                            }}>
                            {this.getDistance(item).toFixed(2) + " km"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>


                ))}

              </>
            }


            <Snackbar
              open={this.state.showAlert}
              autoHideDuration={8000}
              onClose={this.closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this.closeAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }

                style={{
                  marginBottom: "2px",
                  maxWidth: "80vw",
                }}
              >

                <Grid item xs={12}>
                  {/* <Typography  variant="h6" component="div">
                Lifecycle
              </Typography> */}
                  <>
                    <List>

                      <ListItem style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                      }}>
                        <ListItemAvatar>
                          <BookmarkAddIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={t('main.state_inserted')}
                        />
                      </ListItem>

                      <ListItem style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                      }}>
                        <ListItemAvatar>
                          <BookmarkIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={t('main.state_approved')}
                        />
                      </ListItem>

                      <ListItem style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                      }}>
                        <ListItemAvatar>
                          <BookmarkAddedIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={t('main.state_solved')}
                        />
                      </ListItem>

                      <ListItem style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                      }}>
                        <ListItemAvatar>
                          <BookmarkRemoveIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={t('main.state_rejected')}
                        />
                      </ListItem>


                    </List>
                  </>
                </Grid>
              </Alert>
            </Snackbar>

            <>
              <DialogReportDetail
                open={this.state.openDialogDetail}
                close={() => this.closeDialogDetail()}
                itemDetail={this.state.itemDetail}
                kilometres={this.state.kilometres}
                valueNav={this.state.valueNav}
                avatar={this.state.avatar}
                nickname={this.state.nickname}
                cover={this.state.cover}
                badge={this.state.badge}
              />
            </>

          </>
        </div>
      </Suspense>
    );
  }
}

export default withTranslation()(Saved);
